.videos-list {
  padding: 0 20px 20px;
  .box {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
  .video-item {
    cursor: pointer;
    width: calc(50% - 10px);
    height: 42vw;
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #fff;
    margin-bottom: 10px;
    &.inactive {
      background-color: #eeeeef;
    }

    @include screen('mobile') {
      .info-wrapper {
        .box-content {
          background: 'linear-gradient(90deg, #faed00 0%, #e95513 100%)';
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          width: 140px;
          :hover {
            cursor: pointer;
            background: linear-gradient(90deg, #faed00 0%, #e95513 100%);
          }
        }
      }
    }

    @include screen('tablet') {
      .info-wrapper {
        padding-left: 15px;
        .chapter-text,
        .title-text {
          display: block;
          color: #000000;
          text-align: left;
        }

        .box-content {
          background: 'linear-gradient(90deg, #faed00 0%, #e95513 100%)';
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          width: 181px;
          letter-spacing: 1px;
          :hover {
            cursor: pointer;
            background: linear-gradient(90deg, #faed00 0%, #e95513 100%);
          }
        }
      }
    }

    @include screen('mediumTablet') {
      margin-bottom: 2vw;
      flex-direction: row;
      position: relative;
      height: auto;
      justify-content: flex-start;
      padding: 3%;
      box-shadow: 3px 3px 13px #00000026;
      .info-wrapper {
        padding-left: 15px;
        .chapter-text,
        .title-text {
          text-align: left;
          display: block;
          color: #000000;
        }

        .box-content {
          background: 'linear-gradient(90deg, #faed00 0%, #e95513 100%)';
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          width: 181px;
          letter-spacing: 1px;
          :hover {
            cursor: pointer;
            background: linear-gradient(90deg, #faed00 0%, #e95513 100%);
          }
        }
      }
    }

    @include screen('desktop') {
      margin-bottom: 2vw;
      flex-direction: row;
      position: relative;
      height: auto;
      justify-content: flex-start;
      padding: 3%;
      box-shadow: 3px 3px 13px #00000026;
      .info-wrapper {
        padding-left: 15px;
        .chapter-text,
        .title-text {
          text-align: left;
          display: block;
          color: #000000;
        }

        .box-content {
          background: 'linear-gradient(90deg, #faed00 0%, #e95513 100%)';
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          width: 181px;
          letter-spacing: 1px;
          :hover {
            cursor: pointer;
            background: linear-gradient(90deg, #faed00 0%, #e95513 100%);
          }
        }
      }
    }

    .thumb {
      display: flex;
      width: 80%;
      height: 23vw;
      border-radius: 10px;
      overflow: hidden;
      position: relative;

      .video1 {
        // object-fit: cover;
        background-color: grey;
      }

      .video2 {
        object-fit: cover;
        background-color: grey;
        background: transparent linear-gradient(90deg, #c8c9ca 0%, #3e3a39 100%) 0% 0% no-repeat
          padding-box;
        -webkit-mask: url('../imgs/video_thumb.svg') no-repeat center;
        // mask: url('../imgs/video_thumb.svg') no-repeat center;
      }

      .status-line {
        position: absolute;
        left: 0;
        bottom: 0;
        background-color: #e50012;
        height: 5px;
        // width: 100%;
        display: block;
      }
      @include screen('mediumTablet') {
        width: 35%;
        height: 9vw;
      }
      @include screen('desktop') {
        height: 7.5vw;
      }
    }
    .actions {
      width: 80%;
      display: flex;
      justify-content: flex-end;
      margin-top: 3px;
      cursor: pointer;
      .anticon {
        font-size: 14px;
      }
      > span {
        font-size: 14px !important;
        @include screen('tablet') {
          font-size: 17px !important;
        }

        svg {
          fill: #eeeeef;
        }
        &.active {
          svg {
            fill: #e50012;
          }
        }
      }
      .viewed {
        width: 13px;
        height: 13px;
        background: #eeeeef;
        mask-size: 13px 13px;
        -webkit-mask-size: 13px 13px;
        margin-left: 10px;
        @include screen('tablet') {
          width: 16px;
          height: 16px;
          mask-size: 16px 16px;
          -webkit-mask-size: 16px 16px;
        }
        &.active {
          background: #717071;
        }
      }
      @include screen('mediumTablet') {
        width: auto;
        padding: 0 20px;
        position: absolute;
        top: 10%;
        right: 0;
      }
    }

    .title-text {
      font-size: 13px;
      @include screen('mobile') {
        text-align: center;
      }

      @include screen('tablet') {
        text-align: left;
      }

      @include screen('mediumTablet') {
        text-align: left;
      }

      @include screen('desktop') {
        text-align: left;
      }
    }

    .chapter-text {
      display: none;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
}

.ant-tooltip-inner {
  font-size: 11px;
}

.video-detail-wrapper {
  background-color: #fff;
  .video-name {
    font-weight: 500;
    font-size: 15px;
    letter-spacing: 1.5px;
    @include screen('tablet') {
      font-size: 21px;
      letter-spacing: 2px;
    }
    color: #000;
    padding: 0 15px;
    border-bottom: 2px solid #f7f7f7;
    padding-bottom: 20px;
  }
  .document-list {
    padding: 20px 15px;
    margin-bottom: 15px;
    .doc-item {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 10px;
      .txt-name {
        color: #000000;
        font-size: 13px;
      }
      button {
        background: transparent linear-gradient(90deg, #ff9bb1 0%, #ed5e8e 100%) 0% 0% no-repeat
          padding-box;
        border: none;
        span {
          color: #fff;
          letter-spacing: 2px;
          font-size: 13px;
        }
      }
    }
  }
  @include screen('tablet') {
    margin-top: 0 !important;
    padding-left: 7vw;
    padding-right: 7vw;
    padding-top: 3vw;
    background-color: transparent;
    .box {
      background-color: #fff;
    }
  }
  @include screen('desktop') {
    max-height: calc(100vh - 70px);
    overflow-y: auto;
    .box {
      max-width: 820px;
      margin: 0 auto;
    }
  }

  .department-name {
    font-size: 20px;
    color: #000000;
    letter-spacing: 2.5px;
    padding: 0 15px;
    line-height: 1.5;
    @include screen('largeTablet') {
      font-size: 30px;
      letter-spacing: 3px;
    }
    @include screen('desktop') {
      font-size: 32px;
      letter-spacing: 3.2px;
    }
  }

  .actions {
    padding: 0 15px;
    width: 100%;
    display: flex;
    justify-content: flex-end;
    margin-top: 12px;
    cursor: pointer;
    .anticon {
      font-size: 14px;
    }
    > span {
      font-size: 16px !important;
      @include screen('largeTablet') {
        font-size: 25px !important;
      }

      svg {
        fill: #eeeeef;
      }
      &.active {
        svg {
          fill: #e50012;
        }
      }
    }
    .viewed {
      width: 16px;
      height: 16px;
      background: #eeeeef;
      mask-size: 16px 16px;
      -webkit-mask-size: 16px 16px;
      margin-left: 10px;
      @include screen('largeTablet') {
        width: 25px;
        height: 25px;
        mask-size: 25px 25px;
        -webkit-mask-size: 25px 25px;
      }
      &.active {
        background: #717071;
      }
    }
  }
}
.videos-scroll {
  @include screen('tablet') {
    padding: 15px;
  }
  &::-webkit-scrollbar {
    width: 0.5em;
    height: 0.5em;
  }

  &::-webkit-scrollbar-thumb {
    // background-color: #b4b4b5;
    // border-radius: 3px;
    &:hover {
      //  background: rgba(255,255,255,.2);
    }
  }
}
