.lesson-participation-title {
  height: 200px;
  margin: 0 auto;
  text-align: center;
  max-width: 989px;

  .lesson-header {
    margin-top: 50px;
    display: flex;
    margin-bottom: 10px;

    .lesson-icon {
      margin: 0 auto;
      width: 90px;
      height: 80px;
      background: #53d3d3;
      mask-size: 90.2px 72.6px;
      -webkit-mask-size: 90.2px 72.6px;
      display: flex;
    }
    .text {
      margin-left: 20px;
      padding-top: 20px;
      padding-bottom: 20px;
      width: 289px;
      height: 45px;
      font-family: 'Source Han Sans';
      font-size: 30px;
      font-weight: 500;
      line-height: 28px;
      font-style: normal;
      text-align: left;
      color: #000000;
    }
  }

  .text-selected-lesson {
    width: 463px;
    height: 30px;
    display: flex;
    padding-left: 15px;

    font-family: 'Source Han Sans';
    font-size: 20px;
    font-weight: normal;
    line-height: 34px;
    font-style: normal;
    text-align: left;
    color: #3e3a39;
  }
}

.participation-list-wrapper {
  max-width: 1089px;
  height: 667px;
  margin: 0 auto;
  text-align: center;
  // padding-top: 5vw;
  overflow-y: auto;
  overflow-x: hidden;

  &.scroll {
    @include screen('tablet') {
      padding: 15px;
    }
    &::-webkit-scrollbar {
      width: 0.5em;
      height: 0.5em;
    }

    &::-webkit-scrollbar-thumb {
      background-color: #b4b4b5;
      border-radius: 3px;
      &:hover {
        //  background: rgba(255,255,255,.2);
      }
    }
  }

  .mobile-header {
    display: inline-block;
    text-align: center;
    margin-top: 30px;

    @include screen('tablet') {
      display: none;
    }
    .lesson-icon {
      margin: 0 auto;
      width: 25px;
      height: 25px;
      background: #53d3d3;
      mask-size: 25px 25px;
      -webkit-mask-size: 25px 25px;
      display: flex;
    }
    .text {
      margin-top: 5px;
      font-size: 10px;
      letter-spacing: 1.25px;
      color: #888888;
    }
  }

  .mobile-participation-content-wrapper {
    // position: absolute;
    padding: 10px;
    width: 989px;
    height: 122px;
    border-radius: 35px;
    filter: drop-shadow(3px 3px 6.5px rgba(0, 0, 0, 0.15));

    .mobile-lesson-participations,
    .mobile-data {
      height: 100%;
    }
    .mobile-lesson-participations {
      height: 32.2px;
      // width: 348.2px;
      padding-top: 0px;

      align-items: left;
      background: rgba($color: #eeeeef, $alpha: 0.65);
      border-top-left-radius: 14px;
      border-top-right-radius: 14px;
      text-align: center;
      font-size: 19px;

      .title {
        // width: 183px;
        height: 7px;

        font-family: 'SourceHanSans-Regular-90ms-RKSJ-H';
        font-size: 18px;
        font-weight: normal;
        // line-height: 28px;
        font-style: normal;
        text-align: center;
        padding-top: 0px;
        color: #847b3d;
        // padding: 20px;
      }
    }
    .mobile-data {
      // max-width: 348px;
      height: 162.2px;
      margin: auto;
      align-items: left;
      background: #ffffff;
      // padding: 15px 30px;
      border-bottom-right-radius: 14px;
      border-top-right-radius: 14px;
      border-bottom-left-radius: 14px;
      border-top-left-radius: 14px;
      .item {
        .text {
          width: 155px;
          height: 36px;

          font-family: 'Source Han Sans';
          font-size: 18px;
          font-weight: 500;
          line-height: 32px;
          font-style: normal;
          text-align: right;
          color: #000000;

          padding-top: 15px;
          padding-bottom: 60px;
          line-height: 1.2;
          @include screen('tablet') {
            font-size: 20px;
          }
          letter-spacing: 1.78px;
          margin-left: 5px;
          color: #000000;

          .weekeend {
            color: red;
          }
        }

        .mypage-icon {
          width: 40.1px;
          height: 50.3px;

          letter-spacing: 1.78px;
          margin-left: 5px;
          color: #000000;

          margin: 0 auto;
          width: 40px;
          height: 40px;
          background: #b4b4b5;
          mask-size: 40px 40px;
          -webkit-mask-size: 25px 25px;
          display: flex;
        }

        .button-entrance {
          width: 246px;
          height: 41.3px;
          border-radius: 21px;
          background: #888888;

          .button-text {
            width: 69px;
            height: 23px;

            font-family: 'Source Han Sans';
            font-size: 18px;
            font-weight: 500;
            line-height: 27px;
            font-style: normal;
            text-align: center;
            color: #ffffff;
          }
        }
      }
    }
  }

  .activity-item-web {
    height: calc(100% - 20px);
    @include screen('tablet') {
      margin-bottom: 2vw !important;
    }

    @include screen('mediumTablet') {
      width: calc(100% - 20px);
    }

    @include screen('largeTablet') {
      width: calc(100% - 20px);
    }

    @include screen('desktop') {
      width: calc(100% - 25px);
      margin-bottom: 2vw !important;
    }

    .home-web-wrapper {
      height: 146px;

      .mobile-header {
        display: inline-block;
        text-align: center;
        margin-bottom: 10px;
        @include screen('tablet') {
          display: none;
        }
        .text {
          margin-top: 5px;
          font-size: 10px;
          letter-spacing: 1.25px;
          color: #888888;
        }
      }
      .user-info {
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 0 auto;
        margin-bottom: 30px;
        .img-wrapper {
          img {
            width: 35px;
            height: 35px;
            @include screen('tablet') {
              width: 70px;
              height: 70px;
            }

            object-fit: cover;
            border-radius: 50%;
          }
        }
        .name {
          font-size: 18px;
          margin-left: 10px;
          @include screen('tablet') {
            font-size: 25px;
            margin-left: 1.3rem;
          }
          letter-spacing: 2.5px;
          color: #000;
          font-weight: 400;
        }
      }

      h3.title {
        background: #ffe100;
        font-size: 13px;
        @include screen('tablet') {
          font-size: 19px;
        }
        color: #000;
        border-radius: 14px;
        letter-spacing: 2.8px;
        padding: 0.3rem 2rem;
        display: inline-flex;
        margin: 0 auto;
        line-height: 1.42;
      }

      .item-list-wrapper {
        margin-top: 25px;
        padding: 0 30px;
        @include screen('desktop') {
          padding: 0;
        }
        .items,
        .data {
          height: 100%;
        }
        .items {
          height: 206px;
          background: #ffffff;

          border-bottom-left-radius: 35px;
          border-top-left-radius: 35px;
          padding: 15px 23px;
          text-align: center;

          .title {
            font-size: 10px;
            letter-spacing: 1.5px;
            margin-bottom: 15px;
            @include screen('tablet') {
              font-size: 20px;
              letter-spacing: 2.5px;
              margin-bottom: 25px;
            }
            line-height: 1.25;
            color: #000;
            font-weight: 700;
          }
        }
      }

      .participation-content-wrapper {
        // position: absolute;
        margin-top: 25px;
        padding: 0 30px;

        // width: 989px;
        height: 122px;
        border-radius: 25px;
        filter: drop-shadow(3px 3px 6.5px rgba(0, 0, 0, 0.15));
        @include screen('desktop') {
          padding: 10px;
          padding-bottom: 160px;
          padding-left: 40px;
        }
        .lesson-participations,
        .data {
          height: 100%;
        }
        .lesson-participations {
          height: 152.2px;
          padding-top: 40px;
          align-items: left;
          background: rgba($color: #eeeeef, $alpha: 0.65);
          border-bottom-left-radius: 14px;
          border-top-left-radius: 14px;
          text-align: center;
          font-size: 19px;
          .title {
            height: 27px;

            font-family: 'SourceHanSans-Regular-90ms-RKSJ-H';
            font-size: 23px;
            font-weight: normal;
            line-height: 28px;
            font-style: normal;
            text-align: center;
            padding-top: 200px;
            color: #847b3d;

            padding: 20px;
            letter-spacing: 1.5px;
            @include screen('tablet') {
              font-size: 19px;
              letter-spacing: 2.5px;
            }
          }
        }
        .data {
          background: #ffffff;
          border-bottom-right-radius: 14px;
          border-top-right-radius: 14px;
          .item {
            .text {
              width: 155px;
              height: 36px;

              font-family: 'Source Han Sans';
              font-size: 24px;
              font-weight: 500;
              line-height: 32px;
              font-style: normal;
              text-align: left;
              color: #000000;
              padding-top: 35px;
              padding-bottom: 40px;
              line-height: 1.2;

              letter-spacing: 1.78px;
              // margin-left: 5px;
              color: #000000;

              @include screen('tablet') {
                font-size: 16px;
                padding-top: 40px;
                padding-left: 10px;
              }

              @include screen('mediumTablet') {
                font-size: 18px;
                padding-top: 40px;
                padding-left: 20px;
              }
              @include screen('desktop') {
                font-size: 18px;
                padding-top: 40px;
                padding-left: 20px;
              }
              .weekeend {
                color: red;
              }
            }

            .mypage-icon {
              // width: 40.1px;
              height: 50.3px;
              padding: 40px;

              @include screen('tablet') {
                font-size: 12px;
                margin-left: -39px;
                -webkit-mask-size: 35px;
              }

              @include screen('mediumTablet') {
                font-size: 12px;
                margin-left: -10px;
                -webkit-mask-size: 35px;
              }
              @include screen('largeTablet') {
                font-size: 12px;
                margin-left: 20px;
                -webkit-mask-size: 45px;
              }

              @include screen('desktop') {
                font-size: 19px;
                margin-left: 0px;
                -webkit-mask-size: 45px;
              }

              color: #000000;
              // margin: 0 auto;
              // width: 76.9px;
              height: 54.9px;
              background: #888888;
              // background: linear-gradient(180deg, #b7f7ce 0%, #53d3d3 100%);
              //   mask-size: 40px 40px;
              //   -webkit-mask-size: 40px 40px;
              display: flex;
            }

            .button-entrance {
              // width: 246px;
              height: 41.3px;
              border-radius: 21px;
              background: #888888;
              .button-text {
                // width: 69px;
                height: 23px;

                font-family: 'Source Han Sans';
                font-size: 20px;
                font-weight: 500;
                line-height: 27px;
                font-style: normal;
                text-align: center;
                color: #ffffff;
                @include screen('tablet') {
                  height: 15px;
                  width: 49px;
                }
                @include screen('desktop') {
                  height: 23px;
                  width: 69px;
                }
              }

              @include screen('mobile') {
              }
              @include screen('tablet') {
                max-width: 136px;
                // min-width: 600px;
              }
              @include screen('mediumTablet') {
                min-width: 156px;
                max-width: 156px;
              }
              @include screen('largeTablet') {
                min-width: 206px;
                max-width: 226px;
              }
              @include screen('desktop') {
                max-width: 246px;
                min-width: 246px;
                // min-width: 1114px;
              }
            }

            .data-button-space {
              @media (max-width: 777px) {
                margin-left: 0px;
              }
              @include screen('tablet') {
                padding-top: 40px;
                margin-left: 0px;
              }
              @include screen('mediumTablet') {
                padding-top: 40px;
                margin-left: 0px;
                max-width: 400px;
              }

              @include screen('largeTablet') {
                padding-top: 40px;
                margin-left: 40px;
                max-width: 400px;
              }
              @include screen('desktop') {
                padding-top: 40px;
                margin-left: 40px;
              }
            }

            .data-icon-space {
              @include screen('tablet') {
                padding-top: 20px;
                padding-left: 0px;
              }
              @include screen('mediumTablet') {
                padding-top: 20px;
                padding-left: 0px;
              }
              @include screen('desktop') {
                margin-left: 40px;
                // padding-top: 20px;
              }
            }
          }

          @include screen('mobile') {
            // height: 206px;
            padding: 15px 0px;
          }
          @include screen('tablet') {
            // height: 206px;
            max-width: 600px;
            padding: 15px 0px;
          }
          @include screen('desktop') {
            // height: 206px;
            padding: 15px 30px;

            max-width: 1114px;
          }
        }
      }
    }
  }
}
