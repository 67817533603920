.chapter-title {
  height: 200px;
  margin-left: 30px;
  margin-top: -60px;
  // margin: 0 auto;
  // text-align: center;
  max-width: 989px;

  .chapter-header {
    margin-top: 50px;
    display: flex;
    margin-bottom: 10px;

    .chapter-icon {
      padding-right: 80px;
      max-width: 300px;
      height: 80px;
      // background: #f9f9f9;
      mask-size: 90.2px 72.6px;
      -webkit-mask-size: 90.2px 72.6px;
      display: flex;
    }

    .title {
      margin-top: 30px;
      width: 289px;
      height: 45px;
      font-family: 'Source Han Sans';
      font-size: 30px;
      font-weight: 500;
      line-height: 28px;
      font-style: normal;
      text-align: left;
      color: #000000;
      @include screen('tablet') {
        margin-left: 100px;
      }
      @include screen('mediumTablet') {
        margin-left: 100px;
      }
      @include screen('largeTablet') {
        margin-left: 100px;
      }
      @include screen('desktop') {
        margin-left: 50px;
      }
    }
  }

  .text-selected-chapter {
    width: 463px;
    height: 30px;
    display: flex;
    // padding-left: 15px;

    font-family: 'Source Han Sans';
    font-size: 20px;
    font-weight: normal;
    line-height: 34px;
    font-style: normal;
    text-align: left;
    color: #3e3a39;

    /* 問い合わせたい内容を記入してください。 */

    position: absolute;
    width: 419px;
    height: 23px;
    font-family: 'Source Han Sans';
    font-size: 20px;
    font-weight: normal;
    line-height: 34px;
    font-style: normal;
    text-align: left;
    color: #3e3a39;
  }
}
