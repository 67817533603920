.explanation {
  margin: 60px 0;
  text-align: center;
  border-radius: 20px;
  background-color: #fff;
  overflow: hidden;
  box-shadow: 0 0 20px 20px rgba(0, 0, 0, 0.01);

  p {
    margin: 10px 0 0;
    font-size: 18px;
    color: #242424;
  }
  video {
    margin: 0 auto;
  }

  .explainTitle {
    display: flex;
    align-items: center;
    padding: 14px;
    background: #ffe2d7;
    font-size: 20px;
    font-weight: bold;
    color: #a59f72;
  }

  .titleCenter {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
  }

  .explainBody {
    padding: 20px;
  }
}

.btnActions {
  // gap: 100px;
  display: flex;
  justify-content: space-between;
  button {
    color: #101010;
  }
}

.btn {
  cursor: pointer;
  background-color: transparent;
  border: none;
  outline: none;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 4px;
  font-weight: 600;
}

.block {
  cursor: not-allowed;
}

.questionWrap {
  text-align: center;
  max-width: 695px;
  margin: 0 auto;
  padding-bottom: 80px;
  // height: calc(100vh - 162px);

  @media screen and (max-width: 480px) {
    padding-bottom: 0;
  }

  .contentQuestion {
    font-size: 16px;
    color: #595757;
    margin-bottom: 30px;
    // font-family: 'SourceHanSans';
  }

  .listAnswer {
    color: #595757;
    margin: 30px 0;
  }

  :global {
    .wrong-answer {
      color: #717071;
      font-size: 20px;
      display: flex;
      align-items: center;
      font-weight: 700;
      justify-content: end;
      cursor: pointer;
    }

    .index-que {
      color: #53d3d3;
      font-size: 30px;
      display: flex;
      justify-content: center;
    }

    .ant-checkbox-group {
      width: 100%;
    }

    .ant-form-item {
      width: 100%;
    }

    .overall-question {
      display: flex;
      flex-direction: column;
      align-items: center;
      // max-height: calc(100vh - 300px);
      // min-height: 250px;
      // overflow-y: auto;
      width: 100%;
      // @media only screen and (max-width: 600px) {
      //   max-height: 500px;
      // }
    }

    .radio-custom .ant-checkbox-wrapper {
      border: 1px solid #e3e3e3;
      background-color: #fff;
      border-radius: 50px;
      height: 35px;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
    }

    .radio-custom .ant-checkbox-wrapper-checked {
      background: #55d5d5;
    }

    .radio-custom .ant-checkbox {
      z-index: -1;
      width: 0;
    }

    // .show-more {
    //   background-color: rgb(255, 68, 68);
    // }

    // .show-more span {
    //   color: white;
    // }
  }
}

.answerList {
  width: 100%;
}

.txtAnswer {
  color: #231814;
  font-size: 16px;
  font-weight: 400;
  position: relative;
}

.imgCorrect {
  position: absolute;
  top: calc(50%);
  transform: translateY(calc(-50% + 3px));
  right: -30px;
}

.resultWrap {
  max-width: 695px;
  margin: 0 auto;

  :global {
    .dot-result {
      // color: #fff;
      height: 100%;
      width: 100%;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      // margin-bottom: 20px;

      &-good {
        background: linear-gradient(110deg, #53d3d3 0%, #2f58cc 100%);
      }

      &-great {
        background: linear-gradient(110deg, #ffb7c7 0%, #ff5277 100%);
      }

      &-congrats {
      }
    }

    .ant-progress.ant-progress-circle .ant-progress-text {
      top: 50%;
      left: 50%;
      border-radius: 50%;
      height: calc(100% - 42px);
      width: calc(100% - 44px);
      transform: translate(-50%, -50%);
      border: 1px solid #fff;
    }

    .box-title {
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    .correct-option {
      font-size: 80px;
      height: 80px;
      line-height: 80px;
      color: #fff;
    }

    .total-option {
      color: #fff;
      font-size: 31px;
      height: 31px;
      line-height: 31px;
    }

    .index-ans {
      color: #a5a072;
      font-size: 20px;
      margin-right: 10px;
      min-width: 38px;
    }

    .correct-answer {
      height: 20px;
      width: 20px;
      background-color: #f7f7f7;
      border-radius: 50%;
      border: 4px solid #ff5277;
      display: inline-block;
    }

    .wrong-answer {
      height: 20px;
      width: 20px;
      color: #717071;
      font-size: 20px;
      display: flex;
      align-items: center;
      font-weight: 700;
    }

    .col-center {
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      :hover {
        background-color: #fff;
        border-radius: 50px;
      }
    }

    .box-header {
      font-size: 40px;
      color: #a5a072;
      margin: 20px 0;
    }

    .list-answer {
      margin: 20px 0;
    }
  }
}

.boxNote {
  text-align: center;

  color: #a5a072;
  background-color: #fff;
  font-size: 12px;
  border-radius: 50px;
  padding: 12px 60px;
  box-shadow: 0 0 20px 20px rgba(0, 0, 0, 0.01);

  @media (max-width: 768px) {
    padding: 12px;
  }
}

.explainText {
  text-align: center;
}

.link {
  color: #1677ff;
}

.divCenter {
  // margin-bottom: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.abc {
  width: 40px !important;
  height: 40px;
  background-color: #fff;
  margin: 4px;
  font-size: 20px;
  border-radius: 5px;
  border: none;
}
