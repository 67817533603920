.full-page {
  .change-password-wrapper {
    height: 100vh;
    flex-direction: column;
    align-items: center;
    display: flex;
    justify-content: center;
    max-width: 450px;
    margin: 0 auto;
    img.logo {
      @include screen('mobile') {
        width: 110px;
      }
    }

    .title {
      /* パスワードの変更 */
      // width: 209px;
      // height: 27px;
      font-size: 23px;
      font-weight: bold;
      line-height: 27px;
      font-style: normal;
      text-align: left;
      letter-spacing: 2px;
      color: #53d3d3;
    }

    .required-content {
      width: 383px;
      height: 60px;

      font-weight: normal;
      line-height: 21px;
      font-style: normal;
      text-align: center;
      color: #b5b5b5;
      padding-bottom: 80px;
      padding-left: 20px;
      padding-right: 4px;
      @include screen('mobile') {
        font-size: 13px;
        letter-spacing: 1px;
      }
      @include screen('desktop') {
        font-size: 15px;
      }
    }

    .change-password-form {
      @include screen('mobile') {
        width: 80%;
      }
      padding-bottom: 20%;
    }

    .change-password-input {
      .ant-form-item-control-input-content > input,
      .ant-input-affix-wrapper {
        // border: 1px solid #53d3d3;
      }
      .ant-form-item-label {
        line-height: 1;
        padding: 0;
        margin-bottom: 5px;
        label {
          font-size: 14px;
          line-height: 1;
          color: #454b60;
        }
      }

      .input {
        border-color: #53d3d3;
        border-width: 2px;
        border-style: solid;
        border-radius: 24px;
        background: #ffffff;
      }
    }

    .change-password-btn {
      background: transparent linear-gradient(90deg, #2f58cc 0%, #53d3d3 100%) 0% 0% no-repeat
        padding-box;
      border: none;
      width: 100%;
      height: 40px;
      border-radius: 40px;
      > span {
        color: #fff;
      }
    }

    .ant-form-item-explain-error {
      font-size: 12px;
    }
  }
}
