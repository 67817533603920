.ameria-icon {
  -webkit-mask: url('../imgs/ameria.svg') no-repeat center;
  mask: url('../imgs/ameria.svg') no-repeat center;
}

.example-icon {
  -webkit-mask: url('../imgs/example.svg') no-repeat center;
  mask: url('../imgs/example.svg') no-repeat center;
}

.meta-icon {
  -webkit-mask: url('../imgs/meta.svg') no-repeat center;
  mask: url('../imgs/meta.svg') no-repeat center;
}

.list-wrapper {
  margin-bottom: 0 !important;
  display: flex;
  flex-direction: column;
  &:not(.activities-list) {
    @include screen('tablet') {
      padding-left: 3vw !important;
      padding-right: 3vw !important;
      padding-top: 5vw;
      &.videos-list {
        padding-left: calc(3vw - 15px) !important;
        padding-right: calc(3vw - 15px) !important;
        padding-top: calc(5vw - 15px);
      }
    }
    @include screen('largeTablet') {
      padding-left: 7vw !important;
      padding-right: 7vw !important;
      &.videos-list {
        padding-left: 6vw !important;
        padding-right: 6vw !important;
        padding-top: calc(5vw - 15px);
      }
    }
    @include screen('desktop') {
      padding-left: 14.5vw !important;
      padding-right: 14.5vw !important;
      &.videos-list {
        padding-left: 14vw !important;
        padding-right: 14vw !important;
      }
    }
  }
}

.item-list {
  background-color: white;
  border-radius: 35px;
  box-shadow: 3px 3px 13px #00000026;
  margin-bottom: 20px;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 45vw;

  filter: drop-shadow(3px 3px 6.5px rgba(0, 0, 0, 0.15));
  background: #ffffff;

  @include screen('mobile') {
    width: 100%;
  }
  @include screen('tablet') {
    height: 30vw;
    width: calc(50% - 22px);
    margin-bottom: 41px;
    margin: 8px;
  }
  @include screen('mediumTablet') {
    height: 23vw;
  }
  @include screen('largeTablet') {
    // height: 24vw;
  }
  @include screen('desktop') {
    // height: 18vw;

    border-radius: 26px;
    margin-bottom: 20px;
    // width: calc(25% - 10px);
    max-width: calc(22%);
    max-height: 253.6px;
    margin: 10px;
  }
  .icon-wrapper {
    display: flex;
    justify-content: center;
  }
  img {
    max-width: 230px;
    @include screen('tablet') {
      max-height: 90px;
    }
    @include screen('mediumTablet') {
      max-height: 90px;
    }
    @include screen('mobile') {
      // max-width: 150px;
      max-height: 70px;
    }
    margin: 0 auto;
    display: inline-block;
  }
  // .name-wrapper {
  //   text-align: center;
  //   padding: 0 15px;
  //   margin-top: 1rem;
  //   span {
  //     display: inline-block;
  //     color: #000000;
  //     // font-size: 25px;
  //     // letter-spacing: 2.5px;

  //     // width: 159px;
  //     // height: 31px;

  //     font-family: 'Source Han Sans';
  //     font-weight: normal;
  //     line-height: 34px;
  //     font-style: normal;
  //     text-align: center;
  //     color: #000000;

  //     @include screen('mobile') {
  //       font-size: 15px;
  //     }
  //     @include screen('tablet') {
  //       font-size: 18px;
  //     }
  //     @include screen('mediumTablet') {
  //       font-size: 20px;
  //     }
  //     @include screen('desktop') {
  //       font-size: 21px;
  //     }
  //   }
  // }

  .name-wrapper {
    text-align: center;
    padding: 0 15px;
    margin-top: 1rem;

    span {
      display: inline-block;
      color: #000000;
      font-size: 25px;
      letter-spacing: 2.5px;
      @include screen('mobile') {
        font-size: 15px;
      }
      @include screen('tablet') {
        // font-size: 18px;
        font-size: 12px;
      }
      @include screen('mediumTablet') {
        // font-size: 20px;
        font-size: 15px;
      }
      @include screen('desktop') {
        // font-size: 25px;

        font-size: 17px;
        font-weight: 500;
        line-height: 23px;
        font-style: normal;
        text-align: center;
      }
    }

    .school-name {
      color: #000000;
      justify-content: center;
      margin: auto;
      font-weight: 700;
      line-height: 23px;
      font-style: normal;
      text-align: center;

      @include screen('mobile') {
        font-size: 15px;
      }
    }
  }

  .svg-icon {
    width: 90px;
    height: 90px;
    background: transparent linear-gradient(90deg, #c8c9ca 0%, #3e3a39 100%) 0% 0% no-repeat
      padding-box;
    mask-size: 90px 90px;
    -webkit-mask-size: 90px 90px;
    display: flex;
  }

  &:hover {
    background: transparent linear-gradient(180deg, #ff9bb1 0%, #ed5e8e 100%, #ed5e8e 100%) 0% 0%
      no-repeat padding-box;
    .name-wrapper {
      span {
        color: white;
      }
    }
  }

  &.chapter {
    &:hover {
      filter: drop-shadow(3px 3px 6.5px rgba(0, 0, 0, 0.15));
      background: #fff0f5;
      background: linear-gradient(180deg, #fff0f5 0%, #ed5e8e 100%);
      .name-wrapper {
        span {
          color: white;
        }
      }
      .svg-icon {
        background: white;
      }
    }
  }

  &.room {
    &:hover {
      filter: drop-shadow(3px 3px 6.5px rgba(0, 0, 0, 0.15));
      background: #fff0f5;
      background: linear-gradient(180deg, #c8c9ca 0%, #3e3a39 100%);
      .name-wrapper {
        span {
          color: white;
        }
      }
      .svg-icon {
        background: white;
      }
    }

    .svg-icon {
      @include screen('mediumTablet') {
        width: 70px;
        height: 70px;
        mask-size: 70px 70px;
        -webkit-mask-size: 70px 70px;
      }

      @include screen('desktop') {
        width: 90px;
        height: 90px;
        mask-size: 90px 90px;
        -webkit-mask-size: 90px 90px;
      }

      background: transparent linear-gradient(90deg, #c8c9ca 0%, #3e3a39 100%) 0% 0% no-repeat
        padding-box;
      display: flex;
    }
  }
}

.department-list,
.subject-list {
  @include screen('tablet') {
    .box {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
    }
  }

  @include screen('desktop') {
    padding-top: 7vw;
    padding-bottom: 7vw;
    padding-left: 0 !important;
    padding-right: 0 !important;
    display: flex;
    flex-wrap: wrap;
    // justify-content: space-between;
  }
  .item-list.custom-svg {
    &:hover {
      .icon-wrapper {
        img {
          filter: brightness(0) saturate(100%) invert(100%) sepia(100%) saturate(0%)
            hue-rotate(227deg) brightness(108%) contrast(102%);
        }
      }
    }
    .icon-wrapper {
      img {
        filter: brightness(0) saturate(100%) invert(66%) sepia(68%) saturate(4965%)
          hue-rotate(308deg) brightness(100%) contrast(86%);
      }
    }
  }
}

.school {
  .item-list {
    &:hover {
      background: transparent linear-gradient(180deg, #c8c9ca 0%, #3e3a39 100%) 0% 0% no-repeat
        padding-box;
      .svg-icon {
        background: #fff;
      }
    }
  }
}

.chapter-scroll {
  overflow-y: scroll;
  height: 950px;
  // @include screen('tablet') {
  //   padding: 15px;
  // }
  &::-webkit-scrollbar {
    width: 0.5em;
    height: 0.5em;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #b4b4b5;
    border-radius: 3px;
    &:hover {
      //  background: rgba(255,255,255,.2);
    }
  }
}

.room-scroll {
  overflow-y: scroll;
  // height: 950px;

  &::-webkit-scrollbar {
    width: 0.5em;
    height: 0.5em;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #b4b4b5;
    border-radius: 3px;
    &:hover {
      //  background: rgba(255,255,255,.2);
    }
  }
}
