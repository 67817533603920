.full-page {
  .login-wrapper {
    height: 100vh;
    flex-direction: column;
    align-items: center;
    display: flex;
    justify-content: center;
    max-width: 450px;
    margin: 0 auto;
    img.logo {
      @include screen('mobile') {
        width: 110px;
      }
    }

    .login-form {
      @include screen('mobile') {
        width: 80%;
      }
      padding-bottom: 20%;
      
      .ant-form-item-label > label.ant-form-item-required:before {
        content: unset;
      }
    }

    .login-input {
      .ant-form-item-control-input-content > input,
      .ant-input-affix-wrapper {
        border: 2px solid #53d3d3;
      }
      .ant-form-item-label {
        line-height: 1;
        padding: 0;
        margin-bottom: 5px;
        label {
          font-size: 14px;
          line-height: 1;
          color: #454b60;
        }
      }

      .input {
        height: 34px;
        border-color: #53d3d3;
        border-width: 2px;
        border-style: solid;
        border-radius: 24px;
        background: #ffffff;
      }
    }
    // background: transparent linear-gradient(90deg, #2F58CC 0%, #53D3D3 100%) 0% 0% no-repeat padding-box;
    > h3 {
      font-size: 20px;
      color: #231815;
      letter-spacing: 2px;
    }

    .forgot-text {
      font-family: 'Source Han Sans';
      font-size: 13px;
      font-weight: 500;
      line-height: 21px;
      font-style: normal;
      text-align: center;
    }

    .login-btn {
      background: transparent linear-gradient(90deg, #2f58cc 0%, #53d3d3 100%) 0% 0% no-repeat
        padding-box;
      border: none;
      width: 100%;
      height: 40px;
      border-radius: 40px;
      > span {
        color: #fff;
      }

      border-radius: 32px;
    }

    .ant-form-item-explain-error {
      font-size: 12px;
    }
  }
}
