@mixin screen($size) {
  $desktop: '(min-width: 1199px)';
  $largeTablet: '(min-width:1024px)';
  $mediumTablet: '(min-width:801px)';
  $tablet: '(min-width:601px)';
  $largeMobile: '(min-width:480px)';
  $mobile: '(min-width:320px)';
  @if $size == desktop {
    @media only screen and #{$desktop} {
      @content;
    }
  } @else if $size == largeTablet {
    @media only screen and #{$largeTablet} {
      @content;
    }
  } @else if $size == mediumTablet {
    @media only screen and #{$mediumTablet} {
      @content;
    }
  } @else if $size == tablet {
    @media only screen and #{$tablet} {
      @content;
    }
  } @else if $size == largeMobile {
    @media only screen and #{$largeMobile} {
      @content;
    }
  } @else {
    @media only screen and #{$mobile} {
      @content;
    }
  }
}

// .wrapper {
//     margin: 0 auto;
//     width: 100%;
//     @include screen('tablet') {
//       width: 90%;
//     }

//     @include screen('desktop') {
//       width: 85%;
//     }
// }

// .wrapper {
//     @include screen('(min-width: 1367px)') {
//       width: 1280px;
//     }
// }

@mixin text-shorten($numLines: 1) {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;

  @supports (-webkit-line-clamp: $numLines) {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: initial;
    display: -webkit-box;
    -webkit-line-clamp: $numLines;
    -webkit-box-orient: vertical;
  }
}

// @include text-shorten(3);

$spaceSizes: 20;

@mixin margin-classes {
  @for $i from 1 through $spaceSizes {
    $margin: $i * 0.25rem;
    /* margin #{$margin} */
    .m#{$i} {
      margin: $margin;
    }
    .ml#{$i} {
      margin-left: $margin;
    }
    .mr#{$i} {
      margin-right: $margin;
    }
    .mt#{$i} {
      margin-top: $margin;
    }
    .mb#{$i} {
      margin-bottom: $margin;
    }
    .mx#{$i} {
      margin-left: $margin;
      margin-right: $margin;
    }
    .my#{$i} {
      margin-top: $margin;
      margin-bottom: $margin;
    }
  }
}
// @include margin-classes;

@mixin padding-classes {
  @for $i from 1 through $spaceSizes {
    $padding: $i * 0.25rem;
    /* padding #{$padding} */
    .p#{$i} {
      padding: $padding;
    }
    .pl#{$i} {
      padding-left: $padding;
    }
    .pr#{$i} {
      padding-right: $padding;
    }
    .pt#{$i} {
      padding-top: $padding;
    }
    .pb#{$i} {
      padding-bottom: $padding;
    }
    .px#{$i} {
      padding-left: $padding;
      padding-right: $padding;
    }
    .py#{$i} {
      padding-top: $padding;
      padding-bottom: $padding;
    }
  }
}
// @include padding-classes;
