.activities-list {
  position: relative;

  display: flex;
  flex-wrap: wrap;

  .box {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding-top: 3vw;
    padding-bottom: 3vw;

    @include screen('mediumTablet') {
      padding-left: 4vw !important;
      padding-right: 4vw !important;
    }

    @include screen('desktop') {
      padding-left: 12vw !important;
      padding-right: 12vw !important;
      padding-top: 4vw;
      padding-bottom: 4vw;
      width: 100%;
      margin: 0 auto;
    }
  }

  @include screen('tablet') {
    padding-left: 3vw !important;
    padding-right: 3vw !important;
  }

  .activity-item {
    cursor: pointer;
    border-radius: 25px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: calc(50% - 7px);
    height: calc(50vw - 1.25rem - 7px);
    box-shadow: 3px 3px 13px #00000026;

    @include screen('tablet') {
      height: 40vw;
      margin-bottom: 4vw !important;
    }

    @include screen('mediumTablet') {
      width: calc(50% - 20px);
      height: 37vw;
      // margin-bottom: 4vw !important;
    }

    @include screen('largeTablet') {
      width: calc(33.33% - 20px);
      height: 25vw;
      margin-bottom: 4vw !important;
    }

    @include screen('desktop') {
      width: calc(33.33% - 25px);
      height: 20vw;
      margin-bottom: 2vw !important;
    }

    position: relative;

    &::after {
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      border: 0px solid white;
      transition: all 0.2s;
      border-radius: 25px;
      box-sizing: border-box;
    }

    &:hover {
      &::after {
        border-width: 5px;
      }
    }

    .activity-icon {
      display: inline-block;
      mask: none;
      -webkit-mask: none;
      margin-top: 2vw;

      @media screen and (max-width: 600px) {
        &.lesson-icon {
          max-width: 50px;
        }

        &.videos-icon {
          max-width: 40px;
        }

        &.mypage-icon {
          max-width: 40px;
        }

        &.contact-icon {
          max-width: 40px;
        }

        &.contact-chat-icon {
          max-width: 40px;
        }

        &.test-icon {
          max-width: 40px;
        }

        &.message-icon {
          max-width: 43px;
        }

        &.virtual-icon {
          max-width: 43px;
        }
      }

      @include screen('tablet') {
        &.lesson-icon {
          max-width: 60px;
        }

        &.videos-icon {
          max-width: 50px;
        }

        &.mypage-icon {
          max-width: 50px;
        }

        &.contact-icon {
          max-width: 60px;
        }

        &.contact-chat-icon {
          max-width: 40px;
        }

        &.test-icon {
          max-width: 50px;
        }

        &.message-icon {
          max-width: 53px;
        }

        &.virtual-icon {
          max-width: 53px;
        }
      }

      @include screen('mediumTablet') {
        &.lesson-icon {
          max-width: 69px;
        }

        &.videos-icon {
          max-width: 59px;
        }

        &.mypage-icon {
          max-width: 59px;
        }

        &.contact-icon {
          max-width: 60px;
        }

        &.contact-chat-icon {
          max-width: 40px;
        }

        &.test-icon {
          max-width: 59px;
        }

        &.message-icon {
          max-width: 62px;
        }

        &.virtual-icon {
          max-width: 62px;
        }

        margin-top: 4vw;
      }

      @include screen('desktop') {
        margin-top: 2vw;

        &.lesson-icon {
          max-width: 92px;
        }

        &.videos-icon {
          max-width: 70px;
        }

        &.mypage-icon {
          max-width: 70px;
        }

        &.contact-icon {
          max-width: 70px;
        }

        &.contact-chat-icon {
          max-width: 40px;
        }

        &.test-icon {
          max-width: 71px;
        }

        &.message-icon {
          max-width: 80px;
        }

        &.virtual-icon {
          max-width: 75px;
        }
      }
    }

    .title-text {
      background-color: #fff;
      padding: 0 0.7rem;
      color: var(--rt-c-primary-text);
      width: 80%;
      border-radius: 15px;
      letter-spacing: 5px;
      text-align: center;
      display: flex;
      justify-content: center;
      align-items: center;
      min-height: 53px;

      @media screen and (max-width: 280px) {
        letter-spacing: 1px;
        font-size: 10px;
        min-height: 42px;
      }

      @include screen('largeMobile') {
        min-height: 80px;
      }

      @include screen('desktop') {
        min-height: 105px;
      }

      > span {
        white-space: pre-line;
        color: #000;
        font-weight: 400;
        font-size: 13px;

        @include screen('tablet') {
          font-size: 16px;
        }

        @include screen('mediumTablet') {
          font-size: 20px;
        }

        @include screen('desktop') {
          font-size: 27px;
        }
      }
    }

    &.lesson {
      background-color: #d6f2f0;
    }

    &.videos {
      background-color: #f9d9e5;
    }

    &.mypage {
      background-color: #f9f6de;
      // margin-bottom: 200px;
    }

    &.menu {
      background-color: #f9f6de;
      // margin-bottom: 200px;
    }

    &.test {
      background-color: #dedaf7;
    }

    &.message {
      background-color: #f9ede4;
    }

    &.virtual {
      background-color: #eeeeef;
    }

    &.inactive {
      cursor: not-allowed;
      opacity: 0.5;
    }
  }

  .activity-item-web {
    // cursor: pointer;
    // border-radius: 25px;
    // display: flex;
    // flex-direction: column;
    // justify-content: center;
    // align-items: center;
    // width: calc(50% - 7px);
    // height: calc(50vw - 1.25rem - 7px);
    // box-shadow: 3px 3px 13px #00000026;

    height: calc(100% - 20px);
    @include screen('tablet') {
      // height: 30vw;

      margin-bottom: 2vw !important;
      // margin-bottom: 1vw;
    }

    @include screen('mediumTablet') {
      width: calc(100% - 20px);

      // height: 22vw;
      // margin-bottom: 4vw !important;
    }

    @include screen('largeTablet') {
      width: calc(100% - 20px);
      // margin-bottom: 4vw !important;
    }

    @include screen('desktop') {
      width: calc(100% - 25px);
      // height: 20vw;
      // height: 12vw;
      // margin-bottom: 5vw !important;
      margin-bottom: 2vw !important;
    }

    .activity-icon {
      display: inline-block;
      mask: none;
      -webkit-mask: none;
      margin-top: 2vw;

      @include screen('mobile') {
        &.lesson-icon {
          max-width: 50px;
        }

        &.videos-icon {
          max-width: 40px;
        }

        &.mypage-icon {
          max-width: 40px;
        }

        &.contact-icon {
          max-width: 40px;
        }

        &.contact-chat-icon {
          max-width: 40px;
        }

        &.submenu-icon {
          max-width: 40px;
        }

        &.test-icon {
          max-width: 40px;
        }

        &.message-icon {
          max-width: 43px;
        }

        &.virtual-icon {
          max-width: 43px;
        }
      }

      @include screen('tablet') {
        &.lesson-icon {
          max-width: 60px;
        }

        &.videos-icon {
          max-width: 50px;
        }

        &.mypage-icon {
          max-width: 50px;
        }

        &.contact-icon {
          max-width: 60px;
        }

        &.contact-chat-icon {
          max-width: 40px;
        }

        &.submenu-icon {
          max-width: 50px;
        }

        &.test-icon {
          max-width: 50px;
        }

        &.message-icon {
          max-width: 53px;
        }

        &.virtual-icon {
          max-width: 53px;
        }
      }

      @include screen('mediumTablet') {
        &.lesson-icon {
          // max-width: 69px;
          width: 61.5px;
          height: 49.5px;
        }

        &.videos-icon {
          width: 51.9px;
          height: 49.3px;
        }

        &.mypage-icon {
          width: 49.2px;
          height: 52.1px;
        }

        &.contact-icon {
          width: 49.2px;
          height: 52.1px;
        }

        &.contact-chat-icon {
          max-width: 40px;
        }

        &.submenu-icon {
          width: 49.2px;
          height: 52.1px;
        }

        &.test-icon {
          width: 49.2px;
          height: 52.1px;
        }

        &.message-icon {
          width: 49.2px;
          height: 52.1px;
        }

        &.virtual-icon {
          // width: 53.8px;
          // height: 61.9px;
          width: 49.2px;
          height: 52.1px;
        }

        margin-top: 4vw;
      }

      @include screen('desktop') {
        margin-top: 2vw;

        &.lesson-icon {
          max-width: 92px;
        }

        &.videos-icon {
          max-width: 70px;
        }

        &.mypage-icon {
          max-width: 70px;
        }

        &.contact-icon {
          max-width: 70px;
        }

        &.contact-chat-icon {
          max-width: 40px;
        }

        &.submenu-icon {
          max-width: 70px;
        }

        &.test-icon {
          max-width: 71px;
        }

        &.message-icon {
          max-width: 80px;
        }

        &.virtual-icon {
          max-width: 75px;
        }
      }
    }

    .title-text {
      font-family: 'Source Han Sans';
      font-size: 17px;
      font-weight: 500;
      line-height: 21px;
      font-style: normal;
      text-align: center;
      color: #000000;
    }

    .line {
      position: absolute;
      width: 156px;
      height: 4px;
      border-radius: 3px;
      background: #eeeeef;
      position: relative;
      margin-top: 10px;

      transform: translate(-50%, -50%);
      left: 50%;
      align-items: center;
    }

    .description-text {
      // width: 318px;

      position: absolute;
      transform: translate(-50%, -50%);
      padding-top: 20px;
      left: 50%;
      align-items: center;

      width: 166px;
      height: 46px;
      font-family: 'Source Han Sans';
      font-size: 14px;
      font-weight: normal;
      line-height: 25px;
      font-style: normal;
      text-align: left;
      color: #000000;
      // padding-left: 150px;
    }

    &.inactive {
      cursor: not-allowed;
      opacity: 0.5;
    }

    .home-web-wrapper {
      height: 206px;

      .mobile-header {
        display: inline-block;
        text-align: center;
        margin-bottom: 10px;
        @include screen('tablet') {
          display: none;
        }
        .text {
          margin-top: 5px;
          font-size: 10px;
          letter-spacing: 1.25px;
          color: #888888;
        }
      }
      .user-info {
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 0 auto;
        margin-bottom: 30px;
        .img-wrapper {
          img {
            width: 35px;
            height: 35px;
            @include screen('tablet') {
              width: 70px;
              height: 70px;
            }

            object-fit: cover;
            border-radius: 50%;
          }
        }
        .name {
          font-size: 18px;
          margin-left: 10px;
          @include screen('tablet') {
            font-size: 25px;
            margin-left: 1.3rem;
          }
          letter-spacing: 2.5px;
          color: #000;
          font-weight: 400;
        }
      }

      h3.title {
        background: #ffe100;
        font-size: 13px;
        @include screen('tablet') {
          font-size: 19px;
        }
        color: #000;
        border-radius: 14px;
        letter-spacing: 2.8px;
        padding: 0.3rem 2rem;
        display: inline-flex;
        margin: 0 auto;
        line-height: 1.42;
      }

      .item-list-wrapper {
        margin-top: 25px;
        padding: 0 30px;
        @include screen('desktop') {
          padding: 0;
        }
        .items,
        .data {
          height: 100%;
        }
        .items {
          height: 206px;
          background: #ffffff;

          border-bottom-left-radius: 35px;
          border-top-left-radius: 35px;
          padding: 15px 23px;
          text-align: center;

          .title {
            font-size: 10px;
            letter-spacing: 1.5px;
            margin-bottom: 15px;
            @include screen('tablet') {
              font-size: 20px;
              letter-spacing: 2.5px;
              margin-bottom: 25px;
            }
            line-height: 1.25;
            color: #000;
            font-weight: 700;
          }
        }
        .data {
          border-bottom-right-radius: 35px;
          border-top-right-radius: 35px;

          border-top-left-radius: 0px;
          border-top-right-radius: 35px;
          border-bottom-right-radius: 35px;
          border-bottom-left-radius: 0px;
          @include screen('mobile') {
            height: 206px;
          }
          @include screen('tablet') {
            height: 206px;
            max-width: 600px;
          }
          @include screen('desktop') {
            height: 206px;
            max-width: 1114px;
          }

          &.lesson {
            background: #d6f2f0;
            box-shadow: inset 9px 9px 11px #c5e7e8; /* Warning: CSS does not support Inner Shadow.*/
          }

          &.videos {
            background: #f9d9e5;
            box-shadow: inset 9px 9px 11px #f0cdcd; /* Warning: CSS does not support Inner Shadow.*/
          }

          &.mypage {
            background: #fffcd3;
            box-shadow: inset 9px 9px 11px #eee8b7; /* Warning: CSS does not support Inner Shadow.*/
          }

          &.submenu {
            background: #fffcd3;
            box-shadow: inset 9px 9px 11px #eee8b7; /* Warning: CSS does not support Inner Shadow.*/
          }

          &.test {
            background-color: #dedaf7;
            box-shadow: inset 9px 9px 11px #b3bedd; /* Warning: CSS does not support Inner Shadow.*/
          }

          &.message {
            background-color: #f9ede4;
          }

          &.virtual {
            background-color: #eeeeef;
            box-shadow: inset 9px 9px 11px #888888; /* Warning: CSS does not support Inner Shadow.*/
          }
        }
      }
    }
  }

  .box-web {
    @include screen('mediumTablet') {
      // padding-left: 4vw !important;
      // padding-right: 4vw !important;
      padding-top: 1.4vw;
    }

    @include screen('desktop') {
      // padding-left: 12vw !important;
      // padding-right: 12vw !important;
      // padding-top: 4vw;
      // padding-bottom: 4vw;
      padding-top: 1.2vw;
      width: 100%;
      margin: 0 auto;
    }
  }

  .hidden {
    display: none;
  }
}

.sub-activities-list-wrapper {
  .box-web {
    display: flex;
    flex-wrap: nowrap;
    // margin-left: 18px;
    &.scroll {
      overflow-x: scroll;
      // @include screen('tablet') {
      //   padding: 15px;
      // }
      &::-webkit-scrollbar {
        width: 0.5em;
        height: 0.5em;
      }

      &::-webkit-scrollbar-thumb {
        background-color: #b4b4b5;
        border-radius: 3px;
        &:hover {
          //  background: rgba(255,255,255,.2);
        }
      }
    }
  }
}
