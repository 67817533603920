.subject-list {
  padding: 0 1rem 1rem;
  @include screen('tablet') {
    padding-top: 7vw;
    padding-bottom: 7vw;
    padding-left: 6vw !important;
    padding-right: 6vw !important;
    .box {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
    }
  }
}
