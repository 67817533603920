.menu-header {
  .ant-menu-submenu {
    margin-left: auto;
    &:hover::after {
      border-bottom: none;
    }
  }
  .ant-menu-item-selected {
    &::after {
      border-bottom: none !important;
    }
  }

  .icon {
    padding-right: 0px;
    font-weight: 100;
    font-size: 14px;
  }

  .text {
    padding-left: 10px;
    font-weight: 100;
    font-size: 12px;
  }
}

.ant-menu-light.ant-menu-root.ant-menu-vertical {
  border: unset;
}

.ant-breadcrumb {
  height: 46px;
  padding: 12px 20px;
  border-bottom: 1px solid rgba(5, 5, 5, 0.06);
}
