.box-circle {
  width: 16px;
  height: 16px;
  border-radius: 16px;
  background: linear-gradient(90deg, #faed00 0%, #e95513 100%);
}

.ant-notification
  .ant-notification-notice
  .ant-notification-notice-with-icon
  .ant-notification-notice-description {
  padding-right: 40px;
}
