#wrapper {
  width: calc(100% - 256px);
  margin-left: 256px;
}

@include screen('tablet') {
  #root {
    max-height: 100vh;
    overflow: hidden;
  }
}

.master-layout {
  overflow-y: auto;
  overflow-x: hidden;
  height: calc(100vh - 140px);
  // background-color: rgb(247, 247, 247);
  // @include screen('tablet') {
  //   padding: 15px;
  // }
  &::-webkit-scrollbar {
    width: 0.5em;
    height: 0.5em;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #b4b4b5;
    border-radius: 3px;
    &:hover {
      //  background: rgba(255,255,255,.2);
    }
  }

  .content-wrapper {
    &::after {
      content: '';
      position: fixed;
      top: 0;
      left: 0;
      width: 100vw;
      height: 100vh;
      z-index: -1;

      @include screen('tablet') {
        content: none;
      }
    }

    // min-height: 100vh;

    &.background-grey {
      &::after {
        background-color: #f7f7f7;
      }
      // border-radius: 30px;
    }

    &.background-pink {
      &::after {
        background-color: #f9f2f5;
      }
      // border-radius: 30px;
    }

    &.background-blue {
      background-color: #edf9f7;
    }
    border-radius: 30px;

    &.background-purple {
      background-color: #f4f0f8;
    }

    &.background-cornflower {
      background: #ffffff;
      background: linear-gradient(180deg, #ffffff 0%, #c0ecec 100%);
    }

    border-radius: 30px;

    @include screen('mobile') {
      &.background-grey {
        background-color: #f7f7f7;
      }

      &.background-pink {
        background-color: #f9f2f5;
      }

      &.background-blue {
        background-color: #edf9f7;
      }
    }

    @include screen('tablet') {
      &.background-grey {
        background-color: #f7f7f7;
      }

      &.background-pink {
        background-color: #f9f2f5;
      }

      &.background-blue {
        height: 100vh;
        background-color: #edf9f7;
      }

      &.background-purple {
        background-color: #f4f0f8;
      }

      &.background-cornflower {
        background: #ffffff;
        background: linear-gradient(180deg, #ffffff 0%, #c0ecec 100%);
      }

      border-radius: 39px 0px 0px 0px;
      box-shadow: 3px 3px 13px #00000029;
      box-shadow: 2px 1px 5px 0px rgba(0, 0, 0, 0.25) inset;
      -webkit-box-shadow: 2px 1px 5px 0px rgba(0, 0, 0, 0.25) inset;
      -moz-box-shadow: 2px 1px 5px 0px rgba(0, 0, 0, 0.25) inset;
    }

    @include screen('mediumTablet') {
      &.background-grey {
        background-color: #f7f7f7;
        margin-top: 0px;
      }

      &.background-pink {
        background-color: #f9f2f5;
        margin-top: 0px;
      }

      &.background-blue {
        background-color: #edf9f7;
        margin-top: 0px;
        height: 100vh;
      }
    }

    @include screen('largeTablet') {
      &.background-grey {
        background-color: #f7f7f7;
        margin-top: 0px;
      }

      &.background-pink {
        background-color: #f9f2f5;
        margin-top: 0px;
      }

      &.background-blue {
        background-color: #edf9f7;
        margin-top: 0px;
        height: auto;
      }
    }

    @include screen('desktop') {
      &.background-grey {
        background-color: #f7f7f7;
        margin-top: 0px;
      }

      &.background-pink {
        background-color: #f9f2f5;
        margin-top: 0px;
      }

      &.background-blue {
        background-color: #edf9f7;
        margin-top: 0px;
      }
    }

    &.background-yellow {
      background-color: #faf8e5;
    }
  }

  @include screen('tablet') {
    height: calc(100vh);
    padding-left: 75px;
  }

  @include screen('mediumTablet') {
    height: calc(100vh);
    padding-left: 75px;
  }

  @include screen('desktop') {
    height: calc(100vh);
    padding-left: 75px;
  }
}

.sticky-header {
  position: sticky;
  top: 0;
  left: 0;
  z-index: 99;
  background-color: #fff;
}

.sticky-footer {
  position: sticky;
  bottom: 0;
  left: 0;
  z-index: 99;
  background-color: #fff;
}

.master-layout-mobile {
  height: calc(100vh - 140px);
  overflow: auto;
  background-color: rgb(247, 247, 247);

  &.background-grey {
    background-color: #f7f7f7;
  }

  &.background-pink {
    background-color: #f9f2f5;
  }

  &.background-blue {
    background-color: #edf9f7;
  }

  &::-webkit-scrollbar {
    width: 0.5em;
    height: 0.5em;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #b5b4b4;
    border-radius: 3px;
  }

  .content-wrapper {
    // &::after {
    //   content: '';
    //   position: fixed;
    //   top: 0;
    //   left: 0;
    //   width: 100vw;
    //   height: 100vh;
    //   z-index: -1;

    //   @include screen('tablet') {
    //     content: none;
    //   }
    // }

    // min-height: 100vh;

    &.background-grey {
      &::after {
        background-color: #f7f7f7;
      }
      // border-radius: 30px;
    }

    &.background-pink {
      &::after {
        background-color: #f9f2f5;
      }
      // border-radius: 30px;
    }

    &.background-blue {
      background-color: #edf9f7;
    }
    // border-radius: 30px;

    &.background-purple {
      background-color: #f4f0f8;
    }

    &.background-cornflower {
      background: #ffffff;
      background: linear-gradient(180deg, #ffffff 0%, #c0ecec 100%);
    }
    // border-radius: 30px;

    @include screen('mobile') {
      &.background-grey {
        background-color: #f7f7f7;
      }

      &.background-pink {
        background-color: #f9f2f5;
      }

      &.background-blue {
        background-color: #edf9f7;
      }
    }

    @include screen('tablet') {
      &.background-grey {
        background-color: #f7f7f7;
        margin-top: 0px;
      }

      &.background-pink {
        background-color: #f9f2f5;
        margin-top: 0px;
      }

      &.background-blue {
        background-color: #edf9f7;
        margin-top: 0px;
      }
    }

    @include screen('desktop') {
      &.background-grey {
        background-color: #f7f7f7;
        margin-top: 0px;
      }

      &.background-pink {
        background-color: #f9f2f5;
        margin-top: 0px;
      }

      &.background-blue {
        background-color: #edf9f7;
        margin-top: 0px;
      }
    }

    @include screen('mediumTablet') {
      &.background-grey {
        background-color: #f7f7f7;
      }

      &.background-pink {
        background-color: #f9f2f5;
        margin-top: 0px;
      }

      &.background-blue {
        background-color: #edf9f7;
      }
    }

    @include screen('tablet') {
      &.background-grey {
        background-color: #f7f7f7;
      }

      &.background-pink {
        background-color: #f9f2f5;
        margin-top: 0px;
      }

      &.background-blue {
        background-color: #edf9f7;
      }
    }

    @include screen('tablet') {
      &.background-grey {
        background-color: #f7f7f7;
      }

      &.background-pink {
        background-color: #f9f2f5;
      }

      &.background-blue {
        background-color: #edf9f7;
      }

      &.background-purple {
        background-color: #f4f0f8;
      }

      &.background-cornflower {
        background: #ffffff;
        background: linear-gradient(180deg, #ffffff 0%, #c0ecec 100%);
      }

      border-radius: 39px 0px 0px 0px;
      box-shadow: 3px 3px 13px #00000029;
      box-shadow: 2px 1px 5px 0px rgba(0, 0, 0, 0.25) inset;
      -webkit-box-shadow: 2px 1px 5px 0px rgba(0, 0, 0, 0.25) inset;
      -moz-box-shadow: 2px 1px 5px 0px rgba(0, 0, 0, 0.25) inset;
    }

    &.background-yellow {
      background-color: #faf8e5;
    }
  }
}

.header-wrapper {
  &.background-grey {
    background-color: #f7f7f7;
  }

  &.background-pink {
    background-color: #f9f2f5;
  }

  &.background-blue {
    background-color: #edf9f7;
  }

  &.background-purple {
    background-color: #f4f0f8;
  }

  &.background-cornflower {
    background: #ffffff;
    background: linear-gradient(180deg, #ffffff 0%, #c0ecec 100%);
  }

  height: 70px;
  display: flex;
  align-items: center;
  position: relative;

  .mobile,
  .desktop {
    display: none;
  }

  .menu-icon {
    width: 20px;
  }

  .logo {
    height: 20px;
    display: flex;
    margin: auto;
  }

  .right-icon {
    display: flex;
    float: right;
    align-items: center;
  }

  .header-icon {
    cursor: pointer;
    width: 25px;
    height: 40px;
    background: #53d3d3;
    mask-size: 25px 25px;
    -webkit-mask-size: 25px 25px;
    display: flex;
  }

  border-bottom: 4px solid #fff;

  @media (min-width: 280px) {
    .mobile {
      display: flex;
    }
  }

  @include screen('tablet') {
    .mobile {
      display: none;
    }

    .desktop {
      display: flex;
    }

    .logo {
      height: 25px;
      margin: 0;
    }

    .right-icon {
      .item {
        display: flex;
        align-items: center;
        margin-left: 15px;
        ul {
          background: #e6f4ff;
          border-radius: 10px;
        }
      }
    }

    z-index: 2;
    background: #fff;
  }

  @include screen('mobile') {
    .mobile {
      display: none;
    }

    .desktop {
      display: flex;
    }

    .logo {
      height: 25px;
      margin: 0;
      margin-top: 10px;
    }

    .right-icon {
      .item {
        display: flex;
        align-items: center;
        margin-left: 15px;
        ul {
          background: #e6f4ff;
          border-radius: 10px;
        }
      }
    }

    z-index: 2;
    background: #fff;
  }
}

// SVG icons
.home-icon {
  -webkit-mask: url('../imgs/home.svg') no-repeat center;
  mask: url('../imgs/home.svg') no-repeat center;
}

.mini-test-icon {
  -webkit-mask: url('../imgs/minitestmenu.svg') no-repeat center;
  mask: url('../imgs/minitestmenu.svg') no-repeat center;
}

.videos-icon {
  -webkit-mask: url('../imgs/videomenu.svg') no-repeat center;
  mask: url('../imgs/videomenu.svg') no-repeat center;
}

.mypage-icon {
  -webkit-mask: url('../imgs/mypagemenu.svg') no-repeat center;
  mask: url('../imgs/mypagemenu.svg') no-repeat center;
}

.lesson-icon {
  -webkit-mask: url('../imgs/online_lesson.svg') no-repeat center;
  mask: url('../imgs/online_lesson.svg') no-repeat center;
}

.message-icon {
  -webkit-mask: url('../imgs/message.svg') no-repeat center;
  mask: url('../imgs/message.svg') no-repeat center;
}

.virtual-school-icon {
  -webkit-mask: url('../imgs/visual.svg') no-repeat center;
  mask: url('../imgs/visual.svg') no-repeat center;
}

.logout-icon {
  -webkit-mask: url('../imgs/logout.svg') no-repeat center;
  mask: url('../imgs/logout.svg') no-repeat center;
}

.eye-icon {
  -webkit-mask: url('../imgs/eye.svg') no-repeat center;
  mask: url('../imgs/eye.svg') no-repeat center;
}

.classroom-icon {
  -webkit-mask: url('../imgs/presentation.svg') no-repeat center;
  mask: url('../imgs/presentation.svg') no-repeat center;
}

.virtual-room-icon {
  -webkit-mask: url('../imgs/virtual_room.svg') no-repeat center;
  mask: url('../imgs/virtual_room.svg') no-repeat center;
}

.entrance-icon {
  -webkit-mask: url('../imgs/entrance.svg') no-repeat center;
  mask: url('../imgs/entrance.svg') no-repeat center;
}

.presentation-icon {
  -webkit-mask: url('../imgs/classroom.svg') no-repeat center;
  mask: url('../imgs/classroom.svg') no-repeat center;
}

.send-icon {
  -webkit-mask: url('../imgs/send.svg') no-repeat center;
  mask: url('../imgs/send.svg') no-repeat center;
}
// -----

.video-learning-icon {
  -webkit-mask: url('../imgs/video_learning.svg') no-repeat center;
  mask: url('../imgs/video_learning.svg') no-repeat center;
}

.eng-learning-icon {
  -webkit-mask: url('../imgs/eng_learning.svg') no-repeat center;
  mask: url('../imgs/eng_learning.svg') no-repeat center;
}

.eng-lesson-icon {
  -webkit-mask: url('../imgs/eng_lesson.svg') no-repeat center;
  mask: url('../imgs/eng_lesson.svg') no-repeat center;
}

.lesson-participation-icon {
  -webkit-mask: url('../imgs/online_lesson.svg') no-repeat center;
  mask: url('../imgs/online_lesson.svg') no-repeat center;
}

.lesson-archive-icon {
  -webkit-mask: url('../imgs/archive_view.svg') no-repeat center;
  mask: url('../imgs/archive_view.svg') no-repeat center;
}

.contact-icon {
  -webkit-mask: url('../imgs/contact.svg') no-repeat center;
  mask: url('../imgs/contact.svg') no-repeat center;
}

.contact-chat-icon {
  -webkit-mask: url('../imgs/contact_chat.svg') no-repeat center;
  mask: url('../imgs/contact_chat.svg') no-repeat center;
}

.contact-chat-icon {
  -webkit-mask: url('../imgs/contact.svg') no-repeat center;
  mask: url('../imgs/contact.svg') no-repeat center;
}

.submenu-icon {
  -webkit-mask: url('../imgs/mypagemenu.svg') no-repeat center;
  mask: url('../imgs/mypagemenu.svg') no-repeat center;
}
// ------

.bottom-menu-wrapper {
  display: flex;
  justify-content: space-around;
  align-items: center;
  height: 70px;
  position: fixed;
  left: 0;
  bottom: 0;
  background: #fff;
  width: 100%;

  .icon-wrapper {
    cursor: pointer;
    display: flex;
    flex-direction: column;
    align-items: center;

    .text {
      font-size: 8px;
      margin-top: 4px;
      text-align: center;
    }
  }

  .menu-icon {
    width: 20px;
    height: 20px;
    background: #888888;
    mask-size: 20px 20px;
    -webkit-mask-size: 20px 20px;
    display: flex;

    &.active {
      background: transparent linear-gradient(90deg, #ff9bb1 0%, #ed5e8e 100%) 0% 0% no-repeat
        padding-box;

      &.home-icon {
        background: #53d3d3 0% 0% no-repeat padding-box;
      }

      &.mini-test-icon {
        background: transparent linear-gradient(90deg, #53d3d3 0%, #2f58cc 100%) 0% 0% no-repeat
          padding-box;
      }

      &.mypage-icon {
        background: #ffe100;
      }

      &.submenu-icon {
        background: #ffe100;
      }

      &.lesson-icon {
        background: #53d3d3;
      }

      &.contact-chat-icon {
        background: #53d3d3 0% 0% no-repeat padding-box;
      }
    }
  }

  @include screen('tablet') {
    height: 100%;
    width: 75px;
    bottom: auto;
    top: 0;
    flex-direction: column;
    justify-content: flex-start;
    z-index: 1;
    padding-top: 75px;

    .menu-icon {
      width: 30px;
      height: 30px;
      mask-size: 30px 30px;
      -webkit-mask-size: 30px 30px;
    }

    .icon-wrapper {
      &:first-child {
        margin-top: 30px;
      }

      margin-bottom: 30px;

      .text {
        font-size: 10px;
        margin-top: 4px;
      }
    }
  }

  @media screen and (max-width: 600px) {
    position: sticky;
  }
}

.page-title-wrapper {
  .page-title-icon {
    width: 25px;
    height: 25px;
    mask-size: 25px 25px;
    -webkit-mask-size: 25px 25px;
    display: inline-block;
    margin-top: 20px;

    &.home-icon {
      background: #53d3d3;
    }

    &.videos-icon {
      background: transparent linear-gradient(90deg, #ff9bb1 0%, #ed5e8e 100%) 0% 0% no-repeat
        padding-box;
    }

    &.mini-test-icon {
      background: transparent linear-gradient(90deg, #53d3d3 0%, #2f58cc 100%) 0% 0% no-repeat
        padding-box;
    }

    &.virtual-school-icon {
      background: #888888;
    }

    &.lesson-icon {
      background: #53d3d3;
    }
  }

  .title-text {
    font-size: 13px;
  }

  @include screen('tablet') {
    display: none;
  }
}

.page-title-wrapper-web {
  .page-title-icon {
    width: 90px;
    height: 90px;
    mask-size: 90px 90px;
    -webkit-mask-size: 70px 70px;
    display: inline-block;

    &.home-icon {
      background: #53d3d3;
    }

    &.videos-icon {
      background: transparent linear-gradient(90deg, #ff9bb1 0%, #ed5e8e 100%) 0% 0% no-repeat
        padding-box;
    }

    &.mini-test-icon {
      background: transparent linear-gradient(90deg, #53d3d3 0%, #2f58cc 100%) 0% 0% no-repeat
        padding-box;
    }

    &.virtual-school-icon {
      background: #888888;
    }

    &.lesson-icon {
      background: #53d3d3;
    }
  }

  .title-text {
    font-size: 23px;
  }

  @include screen('mobile') {
    display: none;
  }
  @include screen('desktop') {
    display: flex;
  }
  @include screen('tablet') {
    display: flex;
  }

  .item-title {
    height: 200px;
    margin: 0 auto;
    text-align: center;
    max-width: 989px;

    .item-header {
      margin-top: 50px;
      display: flex;
      margin-bottom: 10px;

      .virtual-room-icon {
        margin: 0 auto;
        width: 90px;
        height: 80px;
        background: #131213;
        mask-size: 90.2px 72.6px;
        -webkit-mask-size: 80.2px 72.6px;
        display: flex;

        // width: 65.6px;
        // height: 29.9px;

        border-color: #000000;
        border-width: 1px;
        border-style: dashed;

        background: #c8c9ca;
        background: linear-gradient(180deg, #c8c9ca 0%, #3e3a39 100%);
      }

      .title {
        margin-top: 25px;
        width: 289px;
        height: 45px;
        font-family: 'Source Han Sans';
        font-size: 30px;
        font-weight: 500;
        line-height: 28px;
        font-style: normal;
        text-align: center;
        color: #000000;
        @include screen('tablet') {
          margin-left: 10px;
        }
      }
    }

    .text-selected-item {
      width: 463px;
      height: 30px;
      display: flex;
      padding-left: 15px;

      font-family: 'Source Han Sans';
      font-size: 20px;
      font-weight: normal;
      line-height: 34px;
      font-style: normal;
      text-align: left;
      color: #3e3a39;

      /* 問い合わせたい内容を記入してください。 */

      position: absolute;
      width: 419px;
      height: 23px;
      font-family: 'Source Han Sans';
      font-size: 20px;
      font-weight: normal;
      line-height: 34px;
      font-style: normal;
      text-align: left;
      color: #3e3a39;
    }
  }
}

.w-100 {
  width: 100%;
}
