.list-wrapper {
  margin-bottom: 0 !important;
  display: flex;
  flex-direction: column;
  &:not(.activities-list) {
    @include screen('tablet') {
      padding-left: 3vw !important;
      padding-right: 3vw !important;
      padding-top: 5vw;
      &.videos-list {
        padding-left: calc(3vw - 15px) !important;
        padding-right: calc(3vw - 15px) !important;
        padding-top: calc(5vw - 15px);
      }
    }
    @include screen('largeTablet') {
      padding-left: 7vw !important;
      padding-right: 7vw !important;
      &.videos-list {
        padding-left: 6vw !important;
        padding-right: 6vw !important;
        padding-top: calc(5vw - 15px);
      }
    }
    @include screen('desktop') {
      padding-left: 14.5vw !important;
      padding-right: 14.5vw !important;
      &.videos-list {
        padding-left: 14vw !important;
        padding-right: 14vw !important;
      }
    }
  }
}

.lesson-item-list {
  background-color: white;
  margin-right: 20px;
  cursor: pointer;

  @include screen('mobile') {
    width: 100%;
    max-height: 170px;
  }

  @include screen('tablet') {
    height: 20vw;
    width: calc(50% - 22px);
    margin-bottom: 41px;
  }

  @include screen('mediumTablet') {
  }

  @include screen('largeTablet') {
    height: 24vw;
  }

  @include screen('desktop') {
    height: 18vw;
    margin-bottom: 20px;
  }

  .icon-wrapper {
    display: flex;
    justify-content: center;
  }

  img {
    max-width: 230px;
    @include screen('tablet') {
      max-width: 200px;
    }
    @include screen('mediumTablet') {
      max-width: 200px;
    }
    @include screen('mobile') {
      max-width: 150px;
      max-height: 70px;
    }
    @include screen('desktop') {
      max-width: 100px;
    }
    margin: 0 auto;
    display: inline-block;
  }

  .name-wrapper {
    text-align: center;
    padding: 0 15px;
    margin-top: 1rem;

    span {
      display: inline-block;
      color: #000000;
      font-size: 25px;
      letter-spacing: 2.5px;
      @include screen('mobile') {
        font-size: 15px;
      }
      @include screen('tablet') {
        // font-size: 18px;
        font-size: 12px;
      }
      @include screen('mediumTablet') {
        // font-size: 20px;
        font-size: 15px;
      }
      @include screen('desktop') {
        // font-size: 25px;

        font-size: 17px;
        font-weight: 500;
        line-height: 23px;
        font-style: normal;
        text-align: center;
      }
    }

    .school-name {
      color: #000000;
      justify-content: center;
      margin: auto;
      font-weight: 700;
      line-height: 23px;
      font-style: normal;
      text-align: center;

      @include screen('mobile') {
        font-size: 15px;
      }
    }
  }

  .svg-icon {
    width: 90px;
    height: 90px;
    background: transparent linear-gradient(90deg, #c8c9ca 0%, #3e3a39 100%) 0% 0% no-repeat
      padding-box;
    mask-size: 90px 90px;
    -webkit-mask-size: 90px 90px;
    display: flex;
  }

  &:hover {
    background: transparent linear-gradient(180deg, #b7f7ce 0% 0%, #53d3d3 100%, #53d3d3 100%) 0% 0%
      no-repeat padding-box;
    .name-wrapper {
      span {
        color: white;
      }
    }
  }

  &.room-item {
    .svg-icon {
      width: 50px;
      height: 45px;
      mask-size: 50px 45px;
      -webkit-mask-size: 50px 45px;
      @include screen('mediumTablet') {
        width: 90px;
        height: 90px;
        mask-size: 90px 90px;
        -webkit-mask-size: 90px 90px;
      }

      @include screen('desktop') {
        width: 90px;
        height: 90px;
        mask-size: 90px 90px;
        -webkit-mask-size: 90px 90px;
      }

      background: transparent linear-gradient(90deg, #c8c9ca 0%, #3e3a39 100%) 0% 0% no-repeat
        padding-box;
      display: flex;
    }
    padding: 2.6rem 15px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    .name-wrapper {
      margin-top: 0;
    }
  }
}

.item-list-web {
  background-color: white;
  margin-right: 35px;
  cursor: pointer;
  width: 300px;
  border-radius: 35px;
  display: flex;

  @include screen('mobile') {
    width: 100%;
    height: 17vw;
  }
  // @media (min-width: 600px) {
  //   min-width: 50px;
  //   max-width: 50px;
  //   margin-bottom: 20px;
  //   height: 160px;
  // }

  @include screen('tablet') {
    // height: 18vw;
    min-width: 200px; //calc(50% - 22px);
    max-width: 200px;
    margin-bottom: 20px;
    margin-top: 20px;
    height: 160px;
  }
  @include screen('mediumTablet') {
    // height: 23vw;
    min-width: 200px;
    max-width: 200px;
    height: 160px;
    margin-top: 0;
  }
  @include screen('largeTablet') {
    min-width: 200px;
    max-width: 200px;
    height: 160px;
    margin-top: 0;
  }
  @include screen('desktop') {
    // width: calc(30% - 2px);
    // min-width: 200px;
    min-width: 300px;
    max-width: 300px;
    height: 160px;
    margin-bottom: 20px;
    margin-top: 0;
  }
  .icon-wrapper {
    display: flex;
    justify-content: left;
  }
  // .icon-wrapper-over {
  //   width: 300px;
  //   display: flex;
  //   justify-content: left;
  // }
  img {
    max-width: 230px;
    @include screen('tablet') {
      max-width: 100px;
      height: 50px;
      margin: 40px 0 30px 10px;
    }
    @include screen('mediumTablet') {
      max-width: 200px;
      height: 50px;
    }
    // @include screen('mobile') {
    //   max-width: 90px;
    //   height: 50px;
    //   margin: 40px 0 30px 10px;
    // }
    @include screen('desktop') {
      max-width: 90px;
      height: 90px;
      margin: 30px 0 0px 10px;
    }
    margin: 0 auto;
    display: inline-block;
  }

  .img-custom {
    @include screen('mobile') {
      max-width: 90px;
      height: 50px;
      margin: 50px 0 30px 10px;
    }
    @include screen('tablet') {
      max-width: 150px;
      height: 50px;
      margin: 50px 30px 30px 30px;
    }
    @include screen('mediumTablet') {
      max-width: 150px;
      height: 50px;
      margin: 50px 30px 30px 30px;
    }
    @include screen('largeTablet') {
      max-width: 150px;
      height: 50px;
      margin: 50px 30px 30px 30px;
    }
    @include screen('desktop') {
      // width: 69.7px;
      // max-width: 120px;
      // height: 46.3px;
      max-width: 150px;
      height: 50px;
      margin: 50px 10px 50px 80px;
      // background: #ffffff;
    }
    margin: 0 auto;
    display: inline-block;
  }

  .name-wrapper {
    text-align: left;
    // padding: 0 10px 0 0px;
    // justify-content: left;

    span {
      display: inline-block;
      color: #000000;
      justify-content: left;
      // font-size: 25px;
      // letter-spacing: 2.5px;
      @include screen('mobile') {
        font-size: 15px;
      }
      @include screen('tablet') {
        font-size: 15px;
        margin-top: 3rem;
        padding: 10px 10px 0 5px;
      }
      @include screen('mediumTablet') {
        font-size: 15px;
        margin-left: 10px;
        padding: 10px 10px 0 5px;
      }
      @include screen('desktop') {
        // font-size: 25px;
        padding: 0 10px 0 12px;
        margin-top: 4rem;

        font-size: 17px;
        font-weight: 500;
        line-height: 23px;
        font-style: normal;
        text-align: center;
      }
    }

    .virutal-text {
      display: inline-block;
      color: #000000;
      justify-content: left;
      @include screen('mobile') {
        font-size: 15px;
      }
      @include screen('tablet') {
        font-size: 15px;
        margin-top: 3rem;
        padding: 20px 10px 0 15px;
      }
      @include screen('mediumTablet') {
        font-size: 15px;
        margin-left: 10px;
        padding: 20px 10px 0 5px;
      }
      @include screen('desktop') {
        padding: 0 10px 0 12px;
        margin-top: 4rem;

        font-size: 17px;
        font-weight: 500;
        line-height: 23px;
        font-style: normal;
        text-align: center;
      }
    }

    .department {
      display: inline-block;
      color: #000000;
      justify-content: left;
      font-weight: 500;
      line-height: 23px;
      font-style: normal;
      text-align: center;
      @include screen('mobile') {
        font-size: 15px;
      }
      @include screen('tablet') {
        font-size: 15px;
        padding: 10px 10px 0 5px;
        margin-top: 3rem;
      }
      @include screen('mediumTablet') {
        font-size: 15px;
        padding: 10px 10px 0 5px;
      }
      @include screen('desktop') {
        padding: 10px 10px 0 15px;
        margin-top: 3.5rem;
        font-size: 17px;
      }
    }
  }

  .school-name {
    color: #000000;
    justify-content: center;
    margin: auto;
    font-weight: 700;
    line-height: 23px;
    font-style: normal;
    text-align: center;

    @include screen('mobile') {
      font-size: 15px;
    }
    @include screen('tablet') {
      font-size: 14px;
      padding-left: 10px;
    }
    @include screen('mediumTablet') {
      font-size: 14px;
      // margin-left: 40px;
    }
    @include screen('largeTablet') {
      font-size: 14px;
      width: 200px;

      // margin-left: 40px;
    }
    @include screen('desktop') {
      font-size: 17px;
      // margin-left: 80px;
      width: 300px;
    }
  }

  .svg-icon {
    @include screen('largeMobile') {
      width: 50px;
      max-height: 90px;
      margin: 30px 0 0px 12px;
    }
    @include screen('tablet') {
      width: 50px;
      max-height: 90px;
      margin: 30px 0 0px 12px;
    }
    @include screen('mediumTablet') {
      width: 50px;
      max-height: 90px;
      margin: 30px 0 0px 12px;
    }
    @include screen('largeTablet') {
      width: 50px;
      height: 90px;
      margin: 30px 0 0px 12px;
    }

    @include screen('desktop') {
      width: 90px;
      height: 90px;
      margin: 30px 0 0px 12px;
    }

    margin: 30px 0 0px 10px;
    background: transparent linear-gradient(90deg, #c8c9ca 0%, #3e3a39 100%) 0% 0% no-repeat
      padding-box;
    mask-size: 43.1px 45.7px;
    -webkit-mask-size: 43.1px 45.7px;
    display: flex;

    &.submenu {
      //  background:yellow;
      &:hover {
        filter: drop-shadow(3px 3px 6.5px rgba(0, 0, 0, 0.15));

        background: #fff0f5;
        background: linear-gradient(180deg, #fff0f5 0%, #ed5e8e 100%);
        .name-wrapper {
          span {
            color: white;
          }
        }
      }
    }
  }

  &.videos {
    &:hover {
      filter: drop-shadow(3px 3px 6.5px rgba(0, 0, 0, 0.15));
      background: #fff0f5;
      background: linear-gradient(180deg, #fff0f5 0%, #ed5e8e 100%);
      .name-wrapper {
        span {
          color: white;
        }
      }
      .svg-icon {
        background: white;
      }
    }
  }

  &.school {
    &:hover {
      filter: drop-shadow(3px 3px 6.5px rgba(0, 0, 0, 0.15));

      background: #c8c9ca;
      background: linear-gradient(180deg, #c8c9ca 0%, #3e3a39 100%);
      .name-wrapper {
        span {
          color: white;
        }
      }
      .svg-icon {
        background: white;
      }
    }
  }

  &.submenu {
    &:hover {
      filter: drop-shadow(3px 3px 6.5px rgba(0, 0, 0, 0.15));

      background: #fff9b3;
      background: linear-gradient(180deg, #fff9b3 0%, #fae100 100%);
      .name-wrapper {
        span {
          color: white;
        }
      }
      .svg-icon {
        background: white;
      }
    }
  }

  &.message {
    &:hover {
      filter: drop-shadow(3px 3px 6.5px rgba(0, 0, 0, 0.15));
      background: #fff6da;
      background: linear-gradient(180deg, #fff6da 0%, #ffba1a 100%);
      .name-wrapper {
        span {
          color: white;
        }
      }
      .svg-icon {
        background: white;
      }
    }
  }

  &.test {
    &:hover {
      filter: drop-shadow(3px 3px 6.5px rgba(0, 0, 0, 0.15));

      background: #55d5d5;
      background: linear-gradient(180deg, #55d5d5 0%, #2f58cc 100%);
      .name-wrapper {
        span {
          color: white;
        }
      }
      .svg-icon {
        background: white;
      }
    }
  }

  &.chapter {
    &:hover {
      filter: drop-shadow(3px 3px 6.5px rgba(0, 0, 0, 0.15));
      background: #fff0f5;
      background: linear-gradient(180deg, #fff0f5 0%, #ed5e8e 100%);
      .name-wrapper {
        span {
          color: white;
        }
      }
      .svg-icon {
        background: white;
      }
    }
  }

  &:hover {
    background: transparent linear-gradient(180deg, #b7f7ce 0% 0%, #53d3d3 100%, #53d3d3 100%) 0% 0%
      no-repeat padding-box;
    .name-wrapper {
      span {
        color: white;
      }
    }
  }

  &.room-item {
    .svg-icon {
      width: 50px;
      height: 45px;
      mask-size: 50px 45px;
      -webkit-mask-size: 50px 45px;
      @include screen('mediumTablet') {
        width: 70px;
        height: 63px;
        mask-size: 70px 63px;
        -webkit-mask-size: 70px 63px;
      }

      background: transparent linear-gradient(90deg, #c8c9ca 0%, #3e3a39 100%) 0% 0% no-repeat
        padding-box;
      display: flex;
    }
    padding: 2.6rem 15px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    .name-wrapper {
      margin-top: 0;
    }
  }
}

.department-list,
.subject-list {
  @include screen('tablet') {
    padding-top: 7vw;
    padding-bottom: 7vw;
    padding-left: 6vw !important;
    padding-right: 6vw !important;
    .box {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
    }
  }

  @include screen('desktop') {
    padding-top: 7vw;
    padding-bottom: 7vw;
    padding-left: 0 !important;
    padding-right: 0 !important;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
  .item-list.custom-svg {
    &:hover {
      .icon-wrapper {
        img {
          filter: brightness(0) saturate(100%) invert(100%) sepia(100%) saturate(0%)
            hue-rotate(227deg) brightness(108%) contrast(102%);
        }
      }
    }
    .icon-wrapper {
      img {
        filter: brightness(0) saturate(100%) invert(66%) sepia(68%) saturate(4965%)
          hue-rotate(308deg) brightness(100%) contrast(86%);
      }
    }
  }
}

.school {
  .item-list,
  .item-list-web {
    &:hover {
      background: transparent linear-gradient(180deg, #c8c9ca 0%, #3e3a39 100%) 0% 0% no-repeat
        padding-box;
      .svg-icon {
        background: #fff;
      }
    }
  }
}
