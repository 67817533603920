.mobile-card-p-2 {
  padding-left: 0.5rem !important;
  padding-right: 0rem !important;
  padding-bottom: 0.5rem !important;
  text-align: center;

  // start
  .contact-title {
    height: 150px;
    margin: 0 auto;
    text-align: center;
    max-width: 1989px;

    .contact-header {
      margin-top: 50px;
      display: flex;
      margin-bottom: 10px;

      .contact-chat-icon {
        margin: 0 auto;
        width: 90px;
        height: 80px;
        background: #55d5d5;
        mask-size: 90.2px 72.6px;
        -webkit-mask-size: 90.2px 72.6px;
        display: flex;
      }

      .title {
        // padding-top: 20px;
        // padding-bottom: 20px;
        // margin-left: 10px;
        margin-top: 25px;
        width: 289px;
        height: 45px;
        font-family: 'Source Han Sans';
        font-size: 30px;
        font-weight: 500;
        line-height: 28px;
        font-style: normal;
        text-align: left;
        color: #000000;

        @include screen('tablet') {
          margin-left: 10px;
        }
      }
    }

    .text-selected-contact {
      width: 463px;
      height: 30px;
      display: flex;
      padding-left: 15px;

      font-family: 'Source Han Sans';
      font-size: 20px;
      font-weight: normal;
      line-height: 34px;
      font-style: normal;
      text-align: left;
      color: #3e3a39;

      /* 問い合わせたい内容を記入してください。 */

      position: absolute;
      width: 419px;
      height: 23px;
      font-family: 'Source Han Sans';
      font-size: 20px;
      font-weight: normal;
      line-height: 34px;
      font-style: normal;
      text-align: left;
      color: #3e3a39;
    }
  }

  .d-flex {
    display: flex;
  }

  .justify-content-end {
    justify-content: flex-end !important;
  }

  .align-items-center {
    align-items: stretch !important;

    .ml-3 {
      margin-left: 1rem !important;
    }
  }

  .file {
    width: 40px;
    height: 40px;
    min-width: 40px;
    background: rgb(38, 183, 188);
    border-radius: 50%;
    color: rgb(255, 255, 255);
  }

  .p-2-left {
    padding: 1.5rem !important;
    margin-right: 1.5rem;
    border-radius: 0px 20px 20px 20px;
  }

  .p-2-right {
    padding: 1.5rem !important;
    margin-right: 1.5rem;
    border-radius: 20px 0px 20px 20px;
  }

  .mb-1,
  .my-1 {
    margin-bottom: 0.25rem !important;
  }

  .dir-ltr .ml-3,
  .dir-rtl .mx-3 {
    margin-left: 1rem !important;
  }

  .dir-rtl .ml-3,
  .dir-rtl .mx-3 {
    margin-right: 1rem !important;
  }

  .user {
    background: #26b7bc;
    color: #fff;

    .text-user {
      color: white;
      overflow-wrap: break-word;
      text-align: left;
    }

    .width-content {
      @include screen('tablet') {
        max-width: 10rem;
      }
      @include screen('mediumTablet') {
        max-width: 20rem;
      }
      @include screen('largeTablet') {
        max-width: 30rem;
      }
      @include screen('desktop') {
        max-width: 40rem;
      }
    }
  }

  .admin {
    background: #f7f7f8;
    color: #fff;

    .text-admin {
      color: black;
      overflow-wrap: break-word;
      text-align: left;
    }

    .width-content {
      @include screen('tablet') {
        max-width: 10rem;
      }
      @include screen('mediumTablet') {
        max-width: 20rem;
      }
      @include screen('largeTablet') {
        max-width: 30rem;
      }
      @include screen('desktop') {
        max-width: 40rem;
      }
    }
  }

  ::-webkit-scrollbar {
    width: 8px;
  }

  ::-webkit-scrollbar-track {
    border-radius: 8px;
  }

  ::-webkit-scrollbar-thumb {
    background: rgba(0, 0, 0, 0.2);
    border-radius: 8px;
  }

  // speech bubble

  .speech-bubble-right {
    position: relative;
    background: #55d5d5;
    border-radius: 0.4em;
  }

  .speech-bubble-right:after {
    content: '';
    position: absolute;
    right: 0;
    top: 30%;
    width: 0;
    height: 0;
    border: 20px solid transparent;
    border-left-color: #55d5d5;
    border-right: 0;
    border-top: 0;
    margin-top: -10px;
    margin-right: -20px;
  }

  .speech-bubble-left {
    position: relative;
    background: #ffffff;
    border-radius: 0.4em;
  }

  .speech-bubble-left:after {
    content: '';
    position: absolute;
    left: 0;
    top: 40%;
    width: 0;
    height: 0;
    border: 20px solid transparent;
    border-right-color: #ffffff;
    border-left: 0;
    border-top: 0;
    margin-top: -10px;
    margin-left: -20px;
  }

  // end

  .mobile-header {
    display: inline-block;
    text-align: center;
    margin-top: 15px;

    @include screen('tablet') {
      display: none;
    }

    .contact-chat-icon {
      margin: 0 auto;
      width: 25px;
      height: 25px;
      background: #55d5d5;
      mask-size: 25px 25px;
      -webkit-mask-size: 25px 25px;
      display: flex;
    }

    .mobile-title {
      font-family: 'Source Han Sans';
      font-size: 10px;
      font-weight: 500;
      line-height: 28px;
      font-style: normal;
      text-align: left;
      color: #55d5d5;
    }

    .text {
      margin-top: 5px;
      font-size: 10px;
      letter-spacing: 1.25px;
      color: #888888;
    }
  }

  :where(.css-dev-only-do-not-override-ed5zg0).ant-form-item {
    margin-bottom: 10px;
    margin-top: 10px;
  }
}

.card-p-2 {
  padding-left: 5.5rem !important;
  padding-right: 5.5rem !important;
  padding-bottom: 10.5rem !important;

  // start
  .contact-title {
    height: 150px;
    margin: 0 auto;
    text-align: center;
    max-width: 1989px;

    .contact-header {
      margin-top: 50px;
      display: flex;
      margin-bottom: 10px;

      .contact-chat-icon {
        margin: 0 auto;
        width: 90px;
        height: 80px;
        background: #55d5d5;
        mask-size: 90.2px 72.6px;
        -webkit-mask-size: 90.2px 72.6px;
        display: flex;
      }

      .title {
        // padding-top: 20px;
        // padding-bottom: 20px;
        // margin-left: 10px;
        margin-top: 25px;
        width: 289px;
        height: 45px;
        font-family: 'Source Han Sans';
        font-size: 30px;
        font-weight: 500;
        line-height: 28px;
        font-style: normal;
        text-align: left;
        color: #000000;

        @include screen('tablet') {
          margin-left: 10px;
        }
      }
    }

    .text-selected-contact {
      width: 463px;
      height: 30px;
      display: flex;
      padding-left: 15px;

      font-family: 'Source Han Sans';
      font-size: 20px;
      font-weight: normal;
      line-height: 34px;
      font-style: normal;
      text-align: left;
      color: #3e3a39;

      /* 問い合わせたい内容を記入してください。 */

      position: absolute;
      width: 419px;
      height: 23px;
      font-family: 'Source Han Sans';
      font-size: 20px;
      font-weight: normal;
      line-height: 34px;
      font-style: normal;
      text-align: left;
      color: #3e3a39;
    }
  }

  .d-flex {
    display: flex;
  }

  .justify-content-end {
    justify-content: flex-end !important;
  }

  .align-items-center {
    align-items: stretch !important;

    .ml-3 {
      margin-left: 1rem !important;
    }
  }

  .p-2-left {
    padding: 1rem !important;
    margin-right: 1.5rem;
    border-radius: 0px 20px 20px 20px;
  }

  .p-2-right {
    padding: 1rem !important;
    margin-right: 1.5rem;
    border-radius: 20px 0px 20px 20px;
  }

  .mb-1,
  .my-1 {
    margin-bottom: 0.25rem !important;
  }

  .dir-ltr .ml-3,
  .dir-rtl .mx-3 {
    margin-left: 1rem !important;
  }

  .dir-rtl .ml-3,
  .dir-rtl .mx-3 {
    margin-right: 1rem !important;
  }

  .user {
    background: #26b7bc;
    color: #fff;

    .text-user {
      color: white;
      overflow-wrap: break-word;
    }

    .width-content {
      @include screen('tablet') {
        max-width: 10rem;
      }
      @include screen('mediumTablet') {
        max-width: 20rem;
      }
      @include screen('largeTablet') {
        max-width: 30rem;
      }
      @include screen('desktop') {
        max-width: 40rem;
      }
    }
  }

  .admin {
    background: #f7f7f8;
    color: #fff;

    .text-admin {
      color: black;
      overflow-wrap: break-word;
    }

    .width-content {
      @include screen('tablet') {
        max-width: 10rem;
      }
      @include screen('mediumTablet') {
        max-width: 20rem;
      }
      @include screen('largeTablet') {
        max-width: 30rem;
      }
      @include screen('desktop') {
        max-width: 40rem;
      }
    }
  }

  ::-webkit-scrollbar {
    width: 8px;
  }

  ::-webkit-scrollbar-track {
    border-radius: 8px;
  }

  ::-webkit-scrollbar-thumb {
    background: rgba(0, 0, 0, 0.2);
    border-radius: 8px;
  }

  // speech bubble

  .speech-bubble-right {
    position: relative;
    background: #55d5d5;
    border-radius: 0.4em;
  }

  .speech-bubble-right:after {
    content: '';
    position: absolute;
    right: 0;
    top: 30%;
    width: 0;
    height: 0;
    border: 20px solid transparent;
    border-left-color: #55d5d5;
    border-right: 0;
    border-top: 0;
    margin-top: -10px;
    margin-right: -20px;
  }

  .speech-bubble-left {
    position: relative;
    background: #ffffff;
    border-radius: 0.4em;
  }

  .speech-bubble-left:after {
    content: '';
    position: absolute;
    left: 0;
    top: 40%;
    width: 0;
    height: 0;
    border: 20px solid transparent;
    border-right-color: #ffffff;
    border-left: 0;
    border-top: 0;
    margin-top: -10px;
    margin-left: -20px;
  }

  // end

  // justify for button send & attactment
  .justify {
    justify-content: space-between;
  }

  // margin for TextArea
  :where(.css-dev-only-do-not-override-ed5zg0).ant-form-item {
    margin-bottom: 0;
  }

  // padding card tag
  :where(.css-dev-only-do-not-override-ed5zg0).ant-card .ant-card-body {
    padding: 0;
  }

  :where(.css-dev-only-do-not-override-ed5zg0).ant-input-affix-wrapper > input.ant-input {
    padding-bottom: 0px; // 70px;
  }
}

.d-flex {
  display: flex !important;
}

.justify-content-center {
  justify-content: center !important;
}

.justify-content-end {
  justify-content: flex-end !important;
}

.align-items-center {
  align-items: center !important;
}

.mr-2 {
  margin-right: 0.5rem !important;
}

.file {
  width: 40px;
  height: 40px;
  min-width: 40px;
  background: rgb(38, 183, 188);
  border-radius: 50%;
  color: rgb(255, 255, 255);
}

