.lesson-wrapper {
  // margin-bottom: 0 !important;
  display: flex;
  flex-direction: column;
  @include screen('tablet') {
    padding-left: 3vw !important;
    padding-right: 3vw !important;
    padding-top: 15vw;
    &.lesson-list {
      padding-left: calc(13vw - 15px) !important;
      padding-right: calc(13vw - 15px) !important;
      padding-top: calc(13vw - 15px);
    }
  }
  @include screen('largeTablet') {
    padding-left: 7vw !important;
    padding-right: 7vw !important;
    &.lesson-list {
      padding-left: 6vw !important;
      padding-right: 6vw !important;
      padding-top: calc(13vw - 15px);
    }
  }
  @include screen('desktop') {
    padding-left: 14.5vw !important;
    padding-right: 14.5vw !important;
    &.lesson-list {
      padding-left: 14vw !important;
      padding-right: 14vw !important;
    }
  }
}

.lesson-list {
  @include screen('tablet') {
    // padding-top: 7vw;
    // padding-bottom: 7vw;
    // padding-left: 6vw !important;
    // padding-right: 6vw !important;
    .box {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
    }
  }

  @include screen('desktop') {
    padding-top: 7vw;
    padding-bottom: 7vw;
    padding-left: 0 !important;
    padding-right: 0 !important;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }

  .item-list.custom-svg {
    &:hover {
      .icon-wrapper {
        img {
          filter: brightness(0) saturate(100%) invert(100%) sepia(100%) saturate(0%)
            hue-rotate(227deg) brightness(108%) contrast(102%);
        }
      }
    }
    .icon-wrapper {
      img {
        filter: brightness(0) saturate(100%) invert(66%) sepia(68%) saturate(4965%)
          hue-rotate(308deg) brightness(100%) contrast(86%);
      }
    }
  }
}

.lesson-item-list {
  padding: 0 1rem 1rem;
  max-height: 170px;
  @include screen('tablet') {
    // padding-top: 7vw;
    // padding-bottom: 7vw;
    // padding-left: 6vw !important;
    // padding-right: 6vw !important;
  }

  // background: transparent linear-gradient(180deg, #eeeeef 0% 0%, #b4b4b5 100%) 0% 0% no-repeat
  //   padding-box;
  background: white;
  border-radius: 35px;
  box-shadow: 3px 3px 13px #00000026;
  margin-bottom: 20px;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: center;

  @include screen('mobile') {
    height: 45vw;
    width: 100%;
  }
  @include screen('tablet') {
    height: 40vw;
    width: calc(50% - 22px);
    margin-bottom: 41px;
  }
  @include screen('mediumTablet') {
    height: 23vw;
  }
  @include screen('largeTablet') {
    // height: 24vw;
    height: 18vw;
  }
  @include screen('desktop') {
    height: 18vw;
    margin-bottom: 20px;
  }

  .icon-wrapper {
    display: flex;
    justify-content: left;

    .eng-lesson-icon {
      width: 90px;
      height: 90px;
      background: transparent linear-gradient(90deg, #c8c9ca 0%, #3e3a39 100%) 0% 0% no-repeat
        padding-box;
      mask-size: 90px 90px;
      -webkit-mask-size: 90px 90px;
      display: flex;
    }

    .lesson-participation-icon,
    .lesson-archive-icon {
      width: 90px;
      height: 90px;
      background: transparent linear-gradient(90deg, #c8c9ca 0%, #3e3a39 100%) 0% 0% no-repeat
        padding-box;
      mask-size: 90px 90px;
      -webkit-mask-size: 90px 90px;
      display: flex;
    }
  }

  .name-wrapper {
    text-align: center;
    padding: 0 15px;
    margin-top: 1rem;
    span {
      display: inline-block;
      color: #000000;
      font-size: 25px;
      letter-spacing: 2.5px;
      @include screen('mobile') {
        font-size: 15px;
      }
      @include screen('tablet') {
        font-size: 18px;
      }
      @include screen('mediumTablet') {
        font-size: 20px;
      }
      @include screen('desktop') {
        font-size: 25px;
      }
    }
  }

  &:hover {
    background: transparent linear-gradient(180deg, #b7f7ce 0% 0%, #53d3d3 100%, #53d3d3 100%) 0% 0%
      no-repeat padding-box;
    .name-wrapper {
      span {
        color: white;
      }
    }
    .icon-wrapper {
      .eng-lesson-icon,
      .lesson-archive-icon,
      .lesson-participation-icon {
        background: white;
      }
    }
  }
}

.lesson-item-list-web {
  padding: 0 1rem 1rem;

  border-radius: 35px;
  box-shadow: 3px 3px 13px #00000026;
  margin-bottom: 35px;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-right: 35px;

  @include screen('mobile') {
    height: 45vw;
    width: 100%;
    background: transparent linear-gradient(180deg, #eeeeef 0% 0%, #b4b4b5 100%) 0% 0% no-repeat
      padding-box;
  }
  @include screen('tablet') {
    background: #ffffff;
    min-width: 200px; //calc(50% - 22px);
    max-width: 200px;
    margin-bottom: 20px;
    margin-top: 20px;
    height: 160px;
  }

  @include screen('mediumTablet') {
    min-width: 200px;
    max-width: 200px;
    height: 160px;
    margin-top: 0;
  }
  @include screen('largeTablet') {
    background: #ffffff;
    max-width: 200px;
    min-width: 200px;
    height: 160px;
    margin-top: 0;
  }
  @include screen('desktop') {
    background: #ffffff;
    min-width: 300px;
    height: 160px;
    margin-bottom: 20px;
    margin-right: 35px;
    flex-direction: row;
    margin-top: 0;
  }

  .icon-wrapper {
    display: flex;
    justify-content: center;
    .eng-lesson-icon,
    .lesson-participation-icon,
    .lesson-archive-icon {
      width: 40px;
      height: 40px;
      background: transparent linear-gradient(90deg, #c8c9ca 0%, #3e3a39 100%) 0% 0% no-repeat
        padding-box;
      mask-size: 40px 40px;
      -webkit-mask-size: 40px 40px;
      display: flex;

      @include screen('mobile') {
        width: 90px;
        height: 90px;
        mask-size: 40px 40px;
        -webkit-mask-size: 90px 90px;
        // display: flex;
      }

      @include screen('tablet') {
        -webkit-mask-size: 40px 40px;
        background: #b7f7ce;
        background: linear-gradient(180deg, #b7f7ce 0%, #53d3d3 100%);
      }

      @include screen('desktop') {
        -webkit-mask-size: 40px 40px;

        background: #b7f7ce;
        background: linear-gradient(180deg, #b7f7ce 0%, #53d3d3 100%);
        // max-width: 120px;
        // height: 46.3px;
        width: 90px;
        height: 90px;
        margin: 30px 0 30px 10px;
      }
    }
  }

  .name-wrapper {
    text-align: center;
    padding: 0 15px;
    margin-top: 1rem;
    font-family: 'Source Han Sans';

    span {
      display: inline-block;
      color: #000000;
      font-size: 25px;

      // letter-spacing: 2.5px;
      @include screen('mobile') {
        font-size: 17px;
      }
      @include screen('tablet') {
        font-size: 13px;
      }
      @include screen('mediumTablet') {
        font-size: 15px;
      }
      @include screen('desktop') {
        font-size: 17px;
        padding: 35px 0px 0 10px;
        font-weight: 500;
        line-height: 23px;
        letter-spacing: 1px;
        font-style: normal;
        text-align: center;
      }
    }

    .name-downline {
      letter-spacing: 3px;
      margin-right: 20px;
    }
  }

  &:hover {
    background: transparent linear-gradient(180deg, #b7f7ce 0% 0%, #53d3d3 100%, #53d3d3 100%) 0% 0%
      no-repeat padding-box;
    .name-wrapper {
      span {
        color: white;
      }
    }
    .icon-wrapper {
      .eng-lesson-icon,
      .lesson-archive-icon,
      .lesson-participation-icon {
        background: white;
      }
    }
  }
}
