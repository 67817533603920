@import '__base';
@import '__mixin';
@import '__layout';
@import '__sidebar';
@import '__header';
@import '__login';
@import '__password-change';
@import '__password-forgot';
@import '__home';
@import '__department';
@import '__subject';
@import '__video';
@import '__mypage';
@import '__contact';
@import '__chapter';
@import '__lesson';
@import '__lesson-participation';
@import '__customAnt';
@import '__lesson-archive';
@import '__lesson-department';
@import '__contact-chat';

*,
html {
  color: var(--rt-c-main-gray);
  box-sizing: border-box;
}

body {
  margin: 0;
  font-family: 'Source Han Sans';
  // font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
  //   'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.mb-0 {
  margin-bottom: 0 !important;
}

img {
  max-width: 100%;
}

@include margin-classes;
@include padding-classes;

.background-primary {
  background-color: #f7f7f7;
}

.text-align-center {
  text-align: center;
}

.chapters-carousel {
  .slick-dots li {
    &.slick-active {
      width: 10px;
    }
    width: 10px;
  }
  .slick-dots li.slick-active button {
    width: 10px;
    height: 10px;
    border-radius: 100%;
    background: #ed5e8e 0% 0% no-repeat padding-box;
  }

  .slick-dots li button {
    width: 10px;
    height: 10px;
    border-radius: 100%;
  }
}

.chapters-carousel {
  padding-bottom: 10vw;
  @include screen('tablet') {
    padding: 5vw 0;
  }
  .item-wrapper {
    display: flex;

    @include screen('mobile') {
      flex-direction: column;
    }

    @include screen('largeMobile') {
      flex-direction: column;
    }

    @include screen('tablet') {
      flex-wrap: wrap;
      // justify-content: space-between;
      padding: 0 15px;
      flex-direction: row;
    }

    @include screen('desktop') {
      flex-wrap: wrap;
      // justify-content: space-between;
      padding: 0 15px;
      flex-direction: row;
    }
  }
  .box {
    @include screen('tablet') {
      padding: 0 20px;
    }

    @include screen('mediumTablet') {
      padding: 0 7vw;
    }

    @include screen('desktop') {
      // padding: 0 12vw;
    }
  }

  .ant-carousel .slick-dots-bottom {
    bottom: -20px;
  }
}

.rooms-carousel {
  padding-bottom: 10vw;

  // @include screen('tablet') {
  //   padding: 5vw 0;
  // }

  .item-wrapper {
    display: flex;

    @include screen('mobile') {
      flex-direction: column;
    }

    @include screen('largeMobile') {
      flex-direction: column;
    }

    @include screen('tablet') {
      flex-wrap: wrap;
      // justify-content: space-between;
      padding: 0 15px;
      flex-direction: row;
    }

    @include screen('desktop') {
      flex-wrap: wrap;
      // justify-content: space-between;
      padding: 0 15px;
      flex-direction: row;
    }
  }

  .box {
    @include screen('tablet') {
      padding: 0 20px;
    }

    @include screen('mediumTablet') {
      padding: 0 7vw;
    }

    @include screen('desktop') {
      // padding: 0 12vw;
    }
  }

  .ant-carousel .slick-dots-bottom {
    bottom: -20px;
  }
}

.text-corner {
  font-weight: 700;
  position: fixed;
  right: 10px;
  bottom: 10px;
  font-size: 14px;
}

.card-message .ant-card-body::before {
  display: none;
}

.card-message .ant-card-body::after {
  display: none;
}