.mypage-wrapper {
  max-width: 767px;
  margin: 0 auto;
  text-align: center;
  padding-top: 5vw;

  .mobile-header {
    display: inline-block;
    text-align: center;
    margin-bottom: 10px;
    @include screen('tablet') {
      display: none;
    }
    .mypage-icon {
      margin: 0 auto;
      width: 20px;
      height: 20px;
      background: #ffe100;
      mask-size: 20px 20px;
      -webkit-mask-size: 20px 20px;
      display: flex;
    }
    .text {
      margin-top: 5px;
      font-size: 10px;
      letter-spacing: 1.25px;
      color: #888888;
    }
  }
  .user-info {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
    margin-bottom: 30px;
    .img-wrapper {
      img {
        width: 35px;
        height: 35px;
        @include screen('tablet') {
          width: 70px;
          height: 70px;
        }

        object-fit: cover;
        border-radius: 50%;
      }
    }
    .name {
      font-size: 18px;
      margin-left: 10px;
      @include screen('tablet') {
        font-size: 25px;
        margin-left: 1.3rem;
      }
      letter-spacing: 2.5px;
      color: #000;
      font-weight: 400;
    }
  }
  h3.title {
    background: #ffe100;
    font-size: 13px;
    @include screen('tablet') {
      font-size: 19px;
    }
    color: #000;
    border-radius: 14px;
    letter-spacing: 2.8px;
    padding: 0.3rem 2rem;
    display: inline-flex;
    margin: 0 auto;
    line-height: 1.42;
  }

  .statistic-wrapper {
    margin-top: 25px;
    padding: 0 30px;
    // @include screen('desktop') {
    //   padding: 0;
    // }
    .subjects,
    .data {
      height: 100%;
    }
    .subjects {
      background: rgba($color: #fff, $alpha: 0.65);
      border-bottom-left-radius: 14px;
      border-top-left-radius: 14px;
      padding: 15px 23px;
      text-align: left;

      .title {
        font-size: 10px;
        letter-spacing: 1.5px;
        margin-bottom: 15px;
        @include screen('tablet') {
          font-size: 20px;
          letter-spacing: 2.5px;
          // margin-bottom: 25px;
        }
        line-height: 1.25;
        color: #000;
        font-weight: 700;
      }
      .list {
        .subject-name {
          font-size: 8px;
          line-height: 22px;
          color: #a5a072;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;

          @include screen('tablet') {
            font-size: 15px;
          }
        }
      }
    }
    .data {
      background: #ffffff;
      padding: 15px;
      border-bottom-right-radius: 14px;
      border-top-right-radius: 14px;
      .note {
        display: flex;
        height: 12.5px;
        margin-bottom: 15px;
        @include screen('tablet') {
          height: 25px;
          // margin-bottom: 25px;
        }
        .note-item {
          display: flex;
          align-items: center;
          margin-left: 5px;
          .line {
            width: 16px;
            height: 2px;
            border-radius: 2px;
            @include screen('tablet') {
              width: 35px;
              height: 4px;
            }
            &.first {
              background-color: #53d3d3;
            }
          }
          .text {
            font-size: 6px;
            line-height: 1.2;
            @include screen('tablet') {
              font-size: 13px;
              line-height: 1.78;
            }
            letter-spacing: 1.78px;
            margin-left: 5px;
            color: #53d3d3;
          }
        }
      }
      .process-item {
        display: flex;
        position: relative;
        // margin-bottom: 20px;

        .number {
          font-size: 8px;
          min-width: 20px;
          text-align: center;
          position: absolute;
          top: -10px;

          @include screen('tablet') {
            font-size: 11px;
          }
        }
        .ant-progress-line {
          margin-bottom: 0;
        }
        .ant-progress-outer {
          height: 10px !important;
        }
        .ant-progress-inner {
          // box-shadow: 3px 3px 13px #00000029;
          .ant-progress-bg {
            height: 5px !important;
            // border-right: 3px solid #fff;
            @include screen('tablet') {
              height: 10px !important;
              // border-right: 5px solid #fff;
            }
            border-top-right-radius: 0;
            border-bottom-right-radius: 0;
          }
        }
      }
    }
  }
}
