.contact-title {
  height: 200px;
  margin: 0 auto;
  text-align: center;
  max-width: 989px;

  .contact-header {
    margin-top: 50px;
    display: flex;
    margin-bottom: 10px;

    .contact-icon {
      margin: 0 auto;
      width: 90px;
      height: 80px;
      background: #f995eb;
      mask-size: 90.2px 72.6px;
      -webkit-mask-size: 90.2px 72.6px;
      display: flex;
    }

    .title {
      // padding-top: 20px;
      // padding-bottom: 20px;
      // margin-left: 10px;
      margin-top: 25px;
      width: 289px;
      height: 45px;
      font-family: 'Source Han Sans';
      font-size: 30px;
      font-weight: 500;
      line-height: 28px;
      font-style: normal;
      text-align: left;
      color: #000000;
      @include screen('tablet') {
        margin-left: 10px;
      }
    }
  }

  .text-selected-contact {
    width: 463px;
    height: 30px;
    display: flex;
    padding-left: 15px;

    font-family: 'Source Han Sans';
    font-size: 20px;
    font-weight: normal;
    line-height: 34px;
    font-style: normal;
    text-align: left;
    color: #3e3a39;

    /* 問い合わせたい内容を記入してください。 */

    position: absolute;
    width: 419px;
    height: 23px;
    font-family: 'Source Han Sans';
    font-size: 20px;
    font-weight: normal;
    line-height: 34px;
    font-style: normal;
    text-align: left;
    color: #3e3a39;
  }
}

.contact-list-wrapper {
  max-width: 1089px;
  // height: 1067px;
  margin: 0 auto;
  text-align: center;
  // padding-top: 5vw;
  overflow-y: scroll;
  // margin-bottom: 100px;

  @include screen('mobile') {
    height: calc(100vh - 10px);
  }

  @include screen('tablet') {
    height: calc(100vh - 70px);
  }
  @include screen('smallTablet') {
    height: calc(100vh - 70px);
  }
  @include screen('mediumTablet') {
    height: calc(100vh - 70px);
  }
  @include screen('largeTablet') {
    height: calc(100vh - 70px);
  }
  @include screen('desktop') {
    height: calc(100vh - 70px);
  }

  &.scroll {
    @include screen('tablet') {
      padding: 15px;
    }
    &::-webkit-scrollbar {
      width: 0.5em;
      height: 0.5em;
    }

    &::-webkit-scrollbar-thumb {
      background-color: #b4b4b5;
      border-radius: 3px;
      &:hover {
        //  background: rgba(255,255,255,.2);
      }
    }
  }

  .mobile-header {
    display: inline-block;
    text-align: center;
    margin-top: 25px;
    @include screen('tablet') {
      display: none;
    }

    .contact-icon {
      margin: 0 auto;
      width: 25px;
      height: 25px;
      background: #f995eb;
      mask-size: 25px 25px;
      -webkit-mask-size: 25px 25px;
      display: flex;
    }

    .mobile-title {
      font-family: 'Source Han Sans';
      font-size: 10px;
      font-weight: 500;
      line-height: 28px;
      font-style: normal;
      text-align: left;
      color: #f995eb;
    }

    .text {
      margin-top: 5px;
      font-size: 10px;
      letter-spacing: 1.25px;
      color: #888888;
    }
  }

  .contact-content-wrapper {
    // position: absolute;
    padding: 10px;
    width: 989px;
    height: 122px;
    border-radius: 25px;
    filter: drop-shadow(3px 3px 6.5px rgba(0, 0, 0, 0.15));

    @include screen('desktop') {
      padding-top: 10px;
      padding-bottom: 160px;
      padding-left: 40px;
    }
    .contacts,
    .data {
      height: 100%;
    }

    .contacts {
      height: 152.2px;
      padding-top: 40px;

      align-items: left;
      background: rgba($color: #eeeeef, $alpha: 0.65);
      border-bottom-left-radius: 14px;
      border-top-left-radius: 14px;
      text-align: center;
      font-size: 19px;
    }
  }

  :where(.css-dev-only-do-not-override-ed5zg0).ant-form-item .ant-form-item-label > label {
    color: #b5b5b5;
  }

  :where(.css-dev-only-do-not-override-ed5zg0).ant-form-item .ant-form-item-explain-error {
    text-align: left;
  }

  :where(.css-htwhyh).ant-form-item .ant-form-item-explain-error {
    text-align: left;
  }

  .button {
    cursor: pointer;
    width: 180.7px;
    height: 37.9px;
    border-radius: 19px;
    background: #f995eb;
    color: #ffffff;
    background: linear-gradient(90deg, #f995eb 0%, #c66db6 100%);
  }

  .text {
    /* 送信する */
    width: 77px;
    height: 20px;

    font-family: 'Source Han Sans';
    font-size: 17px;
    font-weight: bold;
    line-height: 27px;
    font-style: normal;
    text-align: left;
    color: #ffffff;
  }

  .mobile-text {
    width: 77px;
    height: 20px;
    font-family: 'Source Han Sans';
    font-size: 17px;
    line-height: 27px;
    font-style: normal;
    text-align: left;
    color: black;
  }
}
